#contents {
  &.privacypolicy {
    .privacypolicy--sec {
      background: $black02;
      @include fz(16);
      line-height: 1.5em;
      color: $white;

      .ttl {
        margin-top: 40px;
      }
      .text {
        margin-top: 20px;
      }
      .list {
        margin-top: 20px;
        margin-left: 1em;
        li {
          // list-style: decimal;
          counter-increment: number 1;
          &::before {
            content: "（" counter(number) "）";
          }
          & + li {
            margin-top: 10px;
          }
        }
        &__text__list {
          margin-top: 20px;
          margin-bottom: 30px;
          margin-left: 1em;
            &__text__list {
            margin-top: 20px;
            margin-left: 1em;
          }
        }
      }
      ul {
        margin-top: 20px;
        li {
          & + li {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
