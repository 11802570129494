#contents {
  &.company {
// ==============================================================================================================
//▼ environmental
//---------------------------------------------------------------------------------------------------------------
    .profile {
      background: $white;
      @include fz(16);
      line-height: 1.8em;
      color: $black;
      @include sp {
        @include fz(14);
        line-height: 1.5em;
      }
      table {
        display: block;
        width: 100%;
        tbody {
          width: 100%;
          display: block;
        }
        tr {
          width: 100%;
          display: flex;
          &:nth-child(2n-1){
            background: #e3e3e3;
          }
          &:nth-child(2n){
            color: $black;
          }
          th {
            width: 30%;
            padding: 4px 4%;
            display: inline-block;
          }
          td {
            width: 70%;
            padding: 4px 4%;
            display: inline-block;
          }
        }
      }
    }
// ==============================================================================================================
//▼ History
//---------------------------------------------------------------------------------------------------------------
    .history {
      background: url(../../common/img/company/bg_history.jpg) left center /cover no-repeat;
      .inner {
        background: $black;
        @include fz(16);
        line-height: 1.8em;
        color: $white;
        margin-top: 30px;
        padding: 67px 32px 27px;
        box-sizing: border-box;
        @include sp {
          @include fz(14);
          line-height: 1.5em;
          padding: 30px 10px 20px;
        }
      }
      table {
        display: block;
        padding: 0 0 0 8%;
        @include sp {
          padding: 0;
        }
        tbody {
          width: 100%;
        }
        tr {
          display: flex;
          & + tr{
            margin-top: 28px;
          }
          th {
            padding-right: 36px;
            position: relative;
            display: inline-block;
            white-space: nowrap;
            @include sp {
              padding-right: 20px;
            }
            &::after {
              content: "";
              display: block;
              width: 32px;
              height: 1px;
              background: $white;
              position: absolute;
              top: 13px;
              right: 0;
              @include sp {
                width: 16px;
                top: 10px;
              }
            }
          }
          td {
            display: inline-block;
            padding-left: 10px;
          }
        }
      }
      .fig--list {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        @include sp {
          flex-wrap: wrap;
        }
        li {
          width: 25%;
          @include sp {
            width: 50%;
          }
        }
      }
    }
// ==============================================================================================================
//▼access
//---------------------------------------------------------------------------------------------------------------
    .access {
      background: $black;
      padding-bottom: 0;
      &__cmn {
        width: 100%;
        background: $black02;
        @include sp {
          & + .access__cmn {
            padding-top: 30px;
          }
        }
        &__inner {
          width: 90%;
          max-width: 890px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          @include tab {
            width: 95%;
            max-width: none;
          }
          @include sp {
            width: 90%;
            display: block;
          }
          .text--area {
            width: 50%;
            color: $white;
            @include tab {
              width: 40%;
            }
            @include sp {
              width: 100%;
            }
            figure {
              width: 100%;
              margin-top: 20px;
            }
            .name {
              @include fz(18);
              line-height: 1.3em;
              font-weight: bold;
              @include sp {
                padding-top: 30px;
                &.border {
                  border-top: 2px solid $white;
                }
              }
            }
            .address {
              @include fz(16);
              line-height: 1.5em;
              margin-top: 30px;
              @include tab {
                margin-top: 20px;
              }
            }
          }
          figure {
            width: 50%;
            @include tab {
              width: 60%;
            }
          }
        }
        .map {
          width: 100%;
          height: calc(462 / 1500 * 100vw);
          background: $black;
          @include sp {
            height: calc(300 / 375 * 100vw);
            margin-top: 10px;
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
// ==============================================================================================================
//▼
//---------------------------------------------------------------------------------------------------------------
// ==============================================================================================================
//▼
//---------------------------------------------------------------------------------------------------------------
  }
}
