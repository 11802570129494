.global--footer {
  width: 100%;
  background: $black;
  color: $white;
  position: relative;
  &__inner {
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 60px 0 43px;
    position: relative;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    position: relative;
    @include sp {
      display: block;
      position: static;
    }
    .information {
      @include sp {
        text-align: center;
      }
      .logo {
        a {
          display: flex;
          justify-content: flex-start;
          @include sp {
            justify-content: center;
          }

        }
        span {
          display: block;
          font-weight: bold;
        }
        .mini {
          @include fz(14);
          line-height: 1.3em;
        }
        .big {
          @include fz(33);
          margin-left: 12px;
        }
      }
      .access {
        @include fz(14);
        line-height: 1.3em;
        margin-top: 30px;
      }
      &__btn {
        margin-top: 24px;
        li {
          width: 100%;
          @include fz(14);
          line-height: 1.3em;
          text-align: center;
          border: 1px solid $white;
          & + li {
            margin-top: 12px;
          }
          a {
            display: block;
            padding: 12px 0;
          }
        }
      }
    }
    .sitemap {
      width: calc(705 / 1024 * 100%);
      display: flex;
      @include fz(14);
      margin-top: 32px;
      @include sp {
        width: 100%;
        display: block;
      }
      &__lists {
        max-width: 254px;
        width: calc(254 / 705 * 100%);
        margin-left: 20px;
        .list {
          & + .list {
            margin-top: 16px;
          }
          &__inner {
            li {
              margin-top: 14px;
              margin-left: 10px;
              padding-left: 20px;
              position: relative;
              &::before,
              &::after {
                content: "";
                display: block;
                background: $white;
                position: absolute;
                left: 0;
              }
              &::before {
                width: 8px;
                height: 1px;
                top: 50%;
                transform: translateY(-50%);
              }
              &::after {
                width: 1px;
                height: 8px;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
  a {
    color: $white;
  }
  .pageTop {
    width: 74px;
    height: 75px;
    position: absolute;
    top: 0;
    right: 0;
    @include sp {
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      display: block;
      background: url(../../common/img/common/pagetop.svg) center center /cover no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    a,span {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }
  #copyright {
    width: 23%;
    @include fz(14);
    font-family: $font_noto;
    color: $black;
    background: $white;
    padding: 24px;
    position: absolute;
    bottom: 0;
    right: 0;
    @include sp {
      width: 100%;
      position: relative;
      text-align: right;
      box-sizing: border-box;
    }
  }
}
