#contents {
  &.product {
    .list--area {
      background: $black02;
      .text--area {
        width: 90%;
        max-width: 856px;
        @include fz(18);
        line-height: 1.8em;
        // text-align: center;
        color: $white;
        margin: 0 auto;
        @include sp {
          width: 100%;
          @include fz(14);
          line-height: 1.8em;
        }
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 70px;
        @include sp {
          flex-wrap: wrap;
        }
        li {
          width: 49%;
          border: 1px solid $gray;
          @include sp {
            width: 100%;
            margin-top: 20px;
          }
          &:nth-child(n+3){
            margin-top: 2%;
            @include sp {
              margin-top: 20px;
            }
          }
          figure {
            img {
              width: 100%;
            }
            figcaption {
              width: 80%;
              margin: 37px auto;
              padding-top: 100px;
              position: relative;
              @include sp {
                margin: 20px auto;
              }
              .text {
                width: 100%;
                @include fz(16);
                line-height: 1.8em;
                font-weight: bold;
                text-align: center;
                color: $white;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                @include sp {
                  @include fz(14);
                  line-height: 1.8em;
                }
              }
              .detail--btn {
                display: block;
                width: 80%;
                margin: 45px auto 0;
                @include sp {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
