#contents {
  &.top {
// ==============================================================================================================
//▼ about--us
//---------------------------------------------------------------------------------------------------------------
    .about--us {
      // background: url(../../common/img/index/bg_about-us.jpg) center center /cover no-repeat;
      background: $white;
      .text--area {
        margin-top: 60px;
        text-align: center;
        .chatch {
          @include fz(32);
          line-height: 1.5em;
          font-weight: bold;
          @include sp {
            @include fz(30);
            line-height: 1.5em;
          }
        }
        .text {
          margin-top: 62px;
          @include fz(16);
          line-height: 1.8em;
          font-weight: 600;
          @include sp {
            text-align: left;
          }
        }
      }
      .link--list {
        display: flex;
        justify-content: space-between;
        max-width: 840px;
        margin: 80px auto 0;
        @include sp {
          flex-direction: column;
        }
        .detail--btn {
          width: 30%;
          min-width: 0;
          @include sp {
            width: 100%;
            & + .detail--btn {
              margin-top: 30px;
            }
          }
        }
      }
    }
// ==============================================================================================================
//▼ philosophy
//---------------------------------------------------------------------------------------------------------------
    .philosophy {
      background: url(../../common/img/index/bg_philosophy.jpg) center center /cover no-repeat;
      .block--link {
        display: block;
        width: 100%;
        height: 100%;
      }
      .text--area {
        font-family: $font_yumin;
        text-align: center;
        color: $white;
        padding-bottom: 50px;
        .text {
          @include fz(20);
          line-height: 1.8em;
        }
        .since {
          margin-top: 60px;
          position: relative;
          .ttl {
            @include fz(110);
            // font-size: calc(110 / 1500 * 100vw);
            font-size: 7.333333vw;
            @include sp {
              @include fz(50);
            }
            span {
              // font-size: calc(90 / 1500 * 100vw);
              font-size: 6vw;
              @include sp {
                @include fz(30);
              }
            }
          }
          &__detail {
            width: calc(420 / 1500 * 100vw);
            font-family: $font_yumin;
            color: $white;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            @include sp {
              width: 100%;
              margin-top: 70px;
              margin-left: auto;
              margin-right: 0;
              position: relative;
              transform: none;
            }
            &::before {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background: $white;
              position: absolute;
              top: 0;
              right: 0;
            }
            &::after {
              content: "";
              display: block;
              width: 17px;
              height: 17px;
              border-radius: 50%;
              margin: 0 auto;
              background: $white;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            .txt {
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              &__inner {
                @include fz(22);
                // margin-top: 18px;
                width: 5em;
                position: absolute;
                top: calc(50% + 34px);
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .year {
                @include fz(15);
              }
              &::before,
              &::after {
                content: "";
                display: block;
                background: $white;
              }
              &::before {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin: 0 auto;
              }
              &::after {
                width: 1px;
                height: 16px;
                position: absolute;
                top: 16px;
                left: 0;
                right: 0;
                margin: 0 auto;
              }
            }
            .sub {
              @include fz(14);
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%) translateY(-140%);
              span {
                @include fz(21);
              }
            }
          }
        }
      }
      &__list {
        width: calc(450 / 1500 * 100vw);
        font-family: $font_yumin;
        color: $white;
        display: flex;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        @include tab {
          width: 60%;
        }
        @include sp {
          width: 95%;
        }
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: $white;
          position: absolute;
          top: 8px;
          right: 0;
          @include sp {
            width: 93%;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          margin: 0 auto;
          background: $white;
          position: absolute;
          left: 0;
          top: 0;
        }
        li {
          position: relative;
          & + li {
            margin-left: 8%;
          }
          &::before,
          &::after {
            content: "";
            display: block;
            background: $white;
          }
          &::before {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin: 0 auto;
          }
          &::after {
            width: 1px;
            height: 16px;
            position: absolute;
            top: 16px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          p {
            @include fz(22);
            margin-top: 18px;
            text-align: center;
            span {
              @include fz(15);
            }
          }
        }
      }
    }
// ==============================================================================================================
//▼ service
//---------------------------------------------------------------------------------------------------------------
    .service {
      // background: url(../../common/img/index/bg_service.jpg) center center /cover no-repeat;
      background: $white;
      .sec--cmn__ttl {
        .jp {
          color: $black;
        }
      }
      &__list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @include sp {
          flex-wrap: wrap;
        }
        li {
          width: 17%;
          @include sp {
            width: 48%;
            &:nth-child(n+3){
              margin-top: 30px;
            }
          }
          figure {
            .fig {
              width: 100%;
              // background: $white;
              border-radius: 50%;
              position: relative;
              margin: 0 auto;
            }
            figcaption {
              @include fz(16);
              line-height: 1.3em;
              font-weight: bold;
              text-align: center;
              color: $black;
              margin-top: 22px;
              @include sp {
                @include fz(14);
                line-height: 1.5em;
                margin-top: 20px;
              }
            }
          }
        }
      }
      .text {
        @include fz(18);
        line-height: 1.3em;
        text-align: center;
        font-weight: bold;
        color: $black ;
        margin-top: 76px;
      }
      .detail--btn {
        display: block;
        max-width: 383px;
        width: 80%;
        margin: 56px auto 0;
      }
    }
// ==============================================================================================================
//▼ product
//---------------------------------------------------------------------------------------------------------------
    .product {
      &::after {
        background: $white;
      }
      .block--type__inner {
        // background: url(../../common/img/index/bg_product.jpg) center center /cover no-repeat;
        background: $black;
        padding-bottom: 52px;
      }
      .text {
        width: 80%;
        max-width: 1024px;
        @include fz(18);
        line-height: 1.8em;
        font-weight: bold;
        text-align: center;
        color: $white;
        margin: 52px auto 0;
      }
      &__list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: $black;
        padding-bottom: 80px;
        @include sp {
          flex-direction: column;
        }
        li {
          width: 33.333333%;
          color: $white;
          text-align: center;
          font-weight: bold;
          padding-bottom: 100px;
          position: relative;
          & + li {
            border-left: 1px solid $white;
          }
          @include sp {
            width: 100%;
            & + li {
              margin-top: 30px;
              border: none;
            }
          }
          &::before {
            content: "";
            display: block;
            width: 100%;
            padding-top: calc(294 / 500 * 100%);
            @include sp {
              padding-top: calc(294 / 375 * 100%);
            }
          }
          &:nth-child(1) {
            &::before {
              background: url(../../common/img/index/product_coating.jpg) center center /cover no-repeat;
            }
          }
          &:nth-child(2) {
            &::before {
              background: url(../../common/img/index/product_plastic.jpg) center center /cover no-repeat;
            }
          }
          &:nth-child(3) {
            &::before {
              background: url(../../common/img/index/product_electronic.jpg) center center /cover no-repeat;
            }
          }
          .name {
            @include fz(22);
            margin-top: 25px;
          }
          .sub {
            width: 94%;
            @include fz(16);
            line-height: 1.5em;
            margin: 25px auto 0;
          }
          .detail--btn {
            width: 70%;
            margin-top: 27px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

// ==============================================================================================================
//▼news
//---------------------------------------------------------------------------------------------------------------
    .news {
      // background: url(../../common/img/index/bg_news.jpg) center center /cover no-repeat;
      background: #F4F4F4;
      &::after {
        display: none;
      }
      &__list {
        width: 90%;
        max-width: 700px;
        margin: 0 auto;
        @include sp {
          width: 100%;
        }
        &__inner {
          background: $white;
          padding: 2% 3%;
          li {
            padding: 10px 0;
            @include sp {
              padding: 20px 0;
            }
            & + li {
              border-top: 1px solid $black;
            }
            .date {
              @include fz(16);
            }
            .text {
              @include fz(16);
              margin-left: 16px;
              @include sp {
                display: block;
                margin-top: 14px;
                margin-left: 0;
              }
            }
            .tag {
              @include fz(14);
              font-weight: bold;
              color: $white;
              background: $red;
              margin-left: 16px;
              padding: 4px 10px;
            }
          }
        }
        .view--all {
          width: 184px;
          text-align: center;
          font-weight: bold;
          position: relative;
          border: none;
          margin-right: 0;
          margin-left: auto;
          @include sp {
            width: 100%;
          }
          a {
            display: block;
            @include fz(12);
            color: $black;
            background: $light-gray;
            padding: 22px 0;
            &::after {
              content: "";
              display: block;
              width: 25px;
              height: 14px;
              background: url(../../common/img/common/direction.svg) center center /cover no-repeat;
              position: absolute;
              top: 50%;
              right: 8%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

// ==============================================================================================================
//▼
//---------------------------------------------------------------------------------------------------------------
  }
}
