@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url(//use.typekit.net/uey4htd.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(//fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap);
@import url(//use.typekit.net/uey4htd.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(//fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap);
@import url(//use.typekit.net/uey4htd.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(//fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap);
@import url(//use.typekit.net/uey4htd.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(//fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap);
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

html, body {
  font-size: 10px;
  line-height: 1em;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

@keyframes scrollBtnTop {
  0% {
    bottom: -18px;
  }
  100% {
    bottom: calc(100% + 18px);
  }
}

@keyframes scrollBtnDown {
  0% {
    top: -18px;
  }
  100% {
    top: calc(100% + 18px);
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .tab {
    display: none !important;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .tab {
    display: none !important;
  }
}

body {
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

.sec--cmn {
  width: 100%;
  padding: 80px 0;
  position: relative;
  box-sizing: border-box;
}

.sec--cmn::before, .sec--cmn::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 53px;
  background: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.sec--cmn::before {
  top: 0;
}

.sec--cmn::after {
  bottom: 0;
}

.sec--cmn:last-child::after {
  display: none;
}

.sec--cmn .inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .sec--cmn .inner {
    max-width: none;
  }
}

.sec--cmn .inner.type--01 {
  max-width: calc(856 / 1500 * 100vw);
}

@media screen and (max-width: 900px) {
  .sec--cmn .inner.type--01 {
    max-width: none;
  }
}

.sec--cmn .inner.type--02 {
  max-width: 890px;
}

@media screen and (max-width: 900px) {
  .sec--cmn .inner.type--02 {
    max-width: none;
  }
}

.sec--cmn .inner.type--03 {
  max-width: 820px;
}

@media screen and (max-width: 900px) {
  .sec--cmn .inner.type--03 {
    max-width: none;
  }
}

.sec--cmn__ttl {
  text-align: center;
}

.sec--cmn__ttl::after {
  content: "";
  display: block;
  width: 2px;
  height: 80px;
  background: #000;
  margin: 0 auto;
  margin-top: 36px;
}

.sec--cmn__ttl .en {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  background: #fff;
  border: 1px solid #707070;
  padding: 8px 26px;
  display: inline-block;
}

.sec--cmn__ttl .jp {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1em;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  display: block;
  margin-top: 40px;
}

.sec--cmn.block--type {
  padding: 0;
}

.sec--cmn.block--type .block--type__inner {
  display: block;
  padding: 80px 0;
}

.sec--cmn.white::before, .sec--cmn.white::after {
  background: #fff;
}

.sec--cmn.white .sec--cmn__ttl .jp {
  color: #fff;
}

.sec--cmn.white .sec--cmn__ttl::after {
  background: #fff;
}

.sec--cmn.none::before, .sec--cmn.none::after {
  display: none;
}

.detail--btn {
  font-weight: bold;
  background: #000;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  width: 266px;
  text-align: center;
}

.detail--btn a {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  color: #fff;
  display: block;
  padding: 28px 0;
  text-decoration: none;
  position: relative;
}

@media screen and (max-width: 767px) {
  .detail--btn a {
    padding: 18px 0;
  }
}

.detail--btn a::after {
  content: "";
  display: block;
  width: 35px;
  height: 20px;
  background: url(../../common/img/common/direction_hover.svg) center center/cover no-repeat;
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
}

.detail--btn:hover {
  background: #fff;
}

.detail--btn:hover a {
  color: #000;
}

.detail--btn:hover a::after {
  background-image: url(../../common/img/common/direction.svg);
}

.detail--btn.white {
  background: #fff;
}

.detail--btn.white a {
  color: #000;
}

.detail--btn.white a::after {
  background-image: url(../../common/img/common/direction.svg);
}

.detail--btn.white:hover {
  background: #000;
}

.detail--btn.white:hover a {
  color: #fff;
}

.detail--btn.white:hover a::after {
  background: url(../../common/img/common/direction_hover.svg) center center/cover no-repeat;
}

.global--nav {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  z-index: 9999;
  transition: 0.4s all;
}

.global--nav__inner {
  width: 100%;
  height: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  background: #000;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  transition: 0.4s;
  z-index: 1;
}

.global--nav__text--area {
  width: calc(1193 / 1500 * 100%);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.4s;
}

.global--nav__text--area .logo {
  width: calc(150 / 1193 * 100%);
  font-weight: bold;
  text-align: right;
  margin-left: calc(30 / 1193 * 100%);
}

@media screen and (max-width: 767px) {
  .global--nav__text--area .logo {
    width: 60%;
    margin-left: 0;
  }
}

.global--nav__text--area .logo a {
  color: #fff;
  display: flex;
  justify-content: center;
}

.global--nav__text--area .logo span {
  display: block;
}

.global--nav__text--area .logo .mini {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1em;
  line-height: 1.3em;
}

.global--nav__text--area .logo .big {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1em;
  margin-left: 12px;
}

.global--nav__text--area .chatch {
  width: calc(600 / 1193 * 100%);
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
}

.global--nav__btn {
  width: calc(288 / 1500 * 100%);
  background: none;
  border: 1px solid #fff;
  border-radius: 37px;
  height: 52px;
  padding: 0;
  box-sizing: border-box;
  transition: 0.4s;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translate(0, -50%);
}

.global--nav__btn:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .global--nav__btn {
    width: 52px;
    border-radius: 50%;
    right: 9px;
  }
}

.global--nav__btn::before, .global--nav__btn::after {
  content: "";
  display: block;
  width: 22px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s all;
}

.global--nav__btn::before {
  top: calc(50% - 12px);
}

.global--nav__btn::after {
  top: calc(50% + 12px);
}

.global--nav__btn span {
  display: block;
  width: 22px;
  height: 1px;
  background: #fff;
  margin: 8px auto;
  transition: 0.4s all;
}

.global--nav #navGlobal {
  width: 100%;
  height: 0;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.4s all;
}

.global--nav #navGlobal .nav__inner {
  max-width: 752px;
  width: 90%;
  margin: 70px auto 68px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav__inner {
    width: 80%;
    display: block;
    margin: 0 auto 30px;
  }
}

.global--nav #navGlobal .nav--fig {
  width: calc(280 / 752 * 100%);
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--fig {
    width: 100%;
    padding-top: calc(144 / 280 * 100%);
  }
}

.global--nav #navGlobal .flex--box {
  width: calc(432 / 752 * 100%);
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .flex--box {
    width: 100%;
    display: block;
    margin-top: 20px;
  }
}

.global--nav #navGlobal .nav--list:nth-child(1) {
  width: calc(252 / 432 *100%);
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list:nth-child(1) {
    width: 100%;
  }
}

.global--nav #navGlobal .nav--list:nth-child(2) {
  width: calc(180 / 432 *100%);
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list:nth-child(2) {
    width: 100%;
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list .list {
    position: relative;
  }
  .global--nav #navGlobal .nav--list .list::before {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    background: url(../../common/img/common/nav_direction.svg) center center/cover no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.global--nav #navGlobal .nav--list .list + .list {
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list .list + .list {
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list .list.none--link:before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list .list .inner--nav {
    height: 0px;
    overflow: hidden;
    transition: 0.3s all;
  }
}

.global--nav #navGlobal .nav--list .list .inner--nav__list {
  margin-top: 14px;
  margin-left: 10px;
  padding-left: 20px;
  position: relative;
}

.global--nav #navGlobal .nav--list .list .inner--nav__list::before, .global--nav #navGlobal .nav--list .list .inner--nav__list::after {
  content: "";
  display: block;
  background: #fff;
  position: absolute;
  left: 0;
}

.global--nav #navGlobal .nav--list .list .inner--nav__list::before {
  width: 8px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
}

.global--nav #navGlobal .nav--list .list .inner--nav__list::after {
  width: 1px;
  height: 8px;
  top: 0;
}

.global--nav #navGlobal .nav--list .type--01 {
  margin-top: 50px;
}

.global--nav #navGlobal .nav--list .type--01__inner .box--link {
  width: 100%;
  text-align: center;
  border: 1px solid #fff;
}

.global--nav #navGlobal .nav--list .type--01__inner .box--link + li {
  margin-top: 20px;
}

.global--nav #navGlobal .nav--list .type--01__inner .box--link a {
  line-height: 1.5em;
  display: block;
  padding: 16px 0;
}

.global--nav #navGlobal .nav--list a, .global--nav #navGlobal .nav--list .nav--link--none {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  color: #fff;
}

.global--nav #navGlobal .nav--list .nav--link--none {
  display: block;
}

@media screen and (max-width: 767px) {
  .global--nav #navGlobal .nav--list .nav--link--none {
    position: relative;
  }
  .global--nav #navGlobal .nav--list .nav--link--none::before {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    background: url(../../common/img/common/nav_direction.svg) center center/cover no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(90deg);
    transition: 0.3s all;
  }
  .global--nav #navGlobal .nav--list .nav--link--none.active::before {
    transform: translateY(-50%) rotate(-90deg);
  }
  .global--nav #navGlobal .nav--list .nav--link--none.active + .inner--nav {
    height: 120px;
  }
}

.global--nav #navGlobal .nav--list a:hover {
  color: #88D1FF;
  text-decoration: underline;
}

.global--nav.active .global--nav__inner {
  width: 70px;
  padding: 0;
}

.global--nav.active .global--nav__text--area {
  width: 0%;
}

.global--nav.active .global--nav__btn {
  width: 52px;
  border-radius: 50%;
  right: 9px;
}

.global--nav.open .global--nav__inner {
  width: 100%;
  padding: 0 18px;
}

.global--nav.open .global--nav__text--area {
  width: calc(1193 / 1500 * 100%);
}

.global--nav.open .global--nav__btn {
  width: calc(288 / 1500 * 100%);
  border-radius: 37px;
  right: 18px;
}

@media screen and (max-width: 767px) {
  .global--nav.open .global--nav__btn {
    width: 52px;
    border-radius: 50%;
    right: 9px;
  }
}

.global--nav.open .global--nav__btn::before, .global--nav.open .global--nav__btn::after {
  margin: auto;
  left: 50%;
  top: 50%;
  position: absolute;
}

.global--nav.open .global--nav__btn::before {
  transform: translateX(-50%) rotate(135deg);
}

.global--nav.open .global--nav__btn::after {
  transform: translateX(-50%) rotate(-135deg);
}

.global--nav.open .global--nav__btn span {
  width: 0;
}

.global--nav.open #navGlobal {
  height: 468px;
  padding-top: 70px;
}

@media screen and (max-width: 767px) {
  .global--nav.open #navGlobal {
    height: 100vh;
    overflow: auto;
  }
}

.global--footer {
  width: 100%;
  background: #000;
  color: #fff;
  position: relative;
}

.global--footer__inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 60px 0 43px;
  position: relative;
}

.global--footer__list {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 767px) {
  .global--footer__list {
    display: block;
    position: static;
  }
}

@media screen and (max-width: 767px) {
  .global--footer__list .information {
    text-align: center;
  }
}

.global--footer__list .information .logo a {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .global--footer__list .information .logo a {
    justify-content: center;
  }
}

.global--footer__list .information .logo span {
  display: block;
  font-weight: bold;
}

.global--footer__list .information .logo .mini {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  line-height: 1.3em;
}

.global--footer__list .information .logo .big {
  font-size: 33px;
  font-size: 3.3rem;
  line-height: 1em;
  margin-left: 12px;
}

.global--footer__list .information .access {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  line-height: 1.3em;
  margin-top: 30px;
}

.global--footer__list .information__btn {
  margin-top: 24px;
}

.global--footer__list .information__btn li {
  width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  line-height: 1.3em;
  text-align: center;
  border: 1px solid #fff;
}

.global--footer__list .information__btn li + li {
  margin-top: 12px;
}

.global--footer__list .information__btn li a {
  display: block;
  padding: 12px 0;
}

.global--footer__list .sitemap {
  width: calc(705 / 1024 * 100%);
  display: flex;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  margin-top: 32px;
}

@media screen and (max-width: 767px) {
  .global--footer__list .sitemap {
    width: 100%;
    display: block;
  }
}

.global--footer__list .sitemap__lists {
  max-width: 254px;
  width: calc(254 / 705 * 100%);
  margin-left: 20px;
}

.global--footer__list .sitemap__lists .list + .list {
  margin-top: 16px;
}

.global--footer__list .sitemap__lists .list__inner li {
  margin-top: 14px;
  margin-left: 10px;
  padding-left: 20px;
  position: relative;
}

.global--footer__list .sitemap__lists .list__inner li::before, .global--footer__list .sitemap__lists .list__inner li::after {
  content: "";
  display: block;
  background: #fff;
  position: absolute;
  left: 0;
}

.global--footer__list .sitemap__lists .list__inner li::before {
  width: 8px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
}

.global--footer__list .sitemap__lists .list__inner li::after {
  width: 1px;
  height: 8px;
  top: 0;
}

.global--footer a {
  color: #fff;
}

.global--footer .pageTop {
  width: 74px;
  height: 75px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .global--footer .pageTop {
    transform: translateY(-50%);
  }
}

.global--footer .pageTop::before {
  content: "";
  display: block;
  background: url(../../common/img/common/pagetop.svg) center center/cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.global--footer .pageTop a, .global--footer .pageTop span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.global--footer #copyright {
  width: 23%;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  color: #000;
  background: #fff;
  padding: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .global--footer #copyright {
    width: 100%;
    position: relative;
    text-align: right;
    box-sizing: border-box;
  }
}

.mv.type--01 {
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
}

.mv.type--01.mv_01 {
  background: url(../../common/img/index/bg_mv_01.jpg) center center/cover no-repeat;
}

.mv.type--01.mv_02 {
  background: url(../../common/img/index/bg_mv_02.jpg) center center/cover no-repeat;
}

.mv.type--01 .mv__slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.mv.type--01 .mv__slider li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 4s all;
}

.mv.type--01 .mv__slider li .scroll--down a {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1em;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;
}

.mv.type--01 .mv__slider li .scroll--down a::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../common/img/index/scrolldown.svg) center center/cover no-repeat;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.mv.type--01 .mv__slider li .scroll--down a::after {
  content: "";
  display: block;
  width: 1px;
  height: 40px;
  background: #fff;
  margin: 30px auto 0;
}

.mv.type--01 .mv__slider li.active {
  opacity: 1;
}

.mv.type--01 .mv__slider li:nth-child(1) {
  background: url(../../common/img/index/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--01 .mv__slider li:nth-child(2) {
  background: url(../../common/img/index/bg_mv_02.jpg) center center/cover no-repeat;
}

.mv.type--01 .mv__slider li:nth-child(2) .scroll--down a {
  color: #000;
}

.mv.type--01 .mv__slider li:nth-child(2) .scroll--down a::before {
  background: url(../../common/img/index/scrolldown_white.svg) center center/cover no-repeat;
}

.mv.type--01 .mv__slider li:nth-child(2) .scroll--down a::after {
  background: #000;
}

.mv.type--01 .mv__inner {
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 10;
  position: relative;
}

.mv.type--01 .mv__inner .mv__canvas {
  width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.mv.type--01 .mv__inner .company--name {
  display: flex;
  justify-content: center;
}

.mv.type--01 .mv__inner .company--name span {
  display: block;
}

.mv.type--01 .mv__inner .company--name .mini {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1em;
  line-height: 1.3em;
}

@media screen and (max-width: 767px) {
  .mv.type--01 .mv__inner .company--name .mini {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1em;
    line-height: 1.3em;
  }
}

.mv.type--01 .mv__inner .company--name .big {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1em;
  margin-left: 12px;
}

@media screen and (max-width: 767px) {
  .mv.type--01 .mv__inner .company--name .big {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1em;
  }
}

.mv.type--01 .mv__inner .sub {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1em;
  margin-top: 46px;
}

@media screen and (max-width: 767px) {
  .mv.type--01 .mv__inner .sub {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1em;
  }
}

.mv.type--01 .mv__inner .chatch {
  display: inline;
  font-size: calc(38 / 680 * 100vh);
  letter-spacing: 18px;
  line-height: 1.7em;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  writing-mode: vertical-rl;
  text-align: left;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .mv.type--01 .mv__inner .chatch {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
    line-height: 1.7em;
    letter-spacing: 10px;
    margin-top: 10px;
  }
}

.mv.type--02 .mv__inner, .mv.type--03 .mv__inner {
  width: 100%;
  height: calc(532 / 1500 * 100vw);
  position: relative;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__inner, .mv.type--03 .mv__inner {
    height: 40vh;
  }
}

.mv.type--02 .mv__ttl, .mv.type--03 .mv__ttl {
  width: 100%;
  height: 168px;
  background: #2A2728;
  position: relative;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__ttl, .mv.type--03 .mv__ttl {
    height: auto;
  }
}

.mv.type--02 .mv__ttl.type--01, .mv.type--03 .mv__ttl.type--01 {
  height: 214px;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__ttl.type--01, .mv.type--03 .mv__ttl.type--01 {
    height: auto;
  }
}

.mv.type--02 .mv__ttl.type--02, .mv.type--03 .mv__ttl.type--02 {
  height: 272px;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__ttl.type--02, .mv.type--03 .mv__ttl.type--02 {
    height: auto;
  }
}

.mv.type--02 .mv__ttl::after, .mv.type--03 .mv__ttl::after {
  content: "";
  display: block;
  width: 2px;
  height: 52px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.mv.type--02 .mv__ttl__cmn, .mv.type--03 .mv__ttl__cmn {
  width: 100%;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__ttl__cmn, .mv.type--03 .mv__ttl__cmn {
    position: relative;
    left: auto;
    transform: none;
  }
}

.mv.type--02 .mv__ttl__cmn span, .mv.type--03 .mv__ttl__cmn span {
  display: block;
}

.mv.type--02 .mv__ttl__cmn .en, .mv.type--03 .mv__ttl__cmn .en {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  background: #fff;
  padding: 16px 36px;
}

.mv.type--02 .mv__ttl__cmn .jp, .mv.type--03 .mv__ttl__cmn .jp {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1em;
  line-height: 1.5em;
  letter-spacing: 4px;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__ttl__cmn .jp, .mv.type--03 .mv__ttl__cmn .jp {
    width: 90%;
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
    margin: 14px auto 0;
    padding-bottom: 30px;
  }
}

.mv.type--02.black .mv__ttl, .mv.type--03.black .mv__ttl {
  background: #000;
}

.mv.type--02 {
  width: 100%;
}

.mv.type--02 .mv__inner {
  background: url(../../common/img/philosophy/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--02 .mv__inner h2 {
  width: 50%;
  text-align: center;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.mv.type--02 .mv__inner h2 span {
  display: block;
}

.mv.type--02 .mv__inner h2 .en {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  background: #fff;
  padding: 4px 36px;
}

.mv.type--02 .mv__inner h2 .jp {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1em;
  line-height: 1.5em;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .mv.type--02 .mv__inner h2 .jp {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
  }
}

.mv.type--03.company .mv__inner {
  background: url(../../common/img/company/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.service .mv__inner {
  background: url(../../common/img/service/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.philosophy .mv__inner {
  background: url(../../common/img/philosophy/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.product .mv__inner {
  background: url(../../common/img/product/bg_mv_coating.jpg) center center/cover no-repeat;
}

.mv.type--03.product_coating .mv__inner {
  background: url(../../common/img/product/bg_mv_coating.jpg) center center/cover no-repeat;
}

.mv.type--03.product_plastic .mv__inner {
  background: url(../../common/img/product/bg_mv_plastic.jpg) center center/cover no-repeat;
}

.mv.type--03.product_electronic .mv__inner {
  background: url(../../common/img/product/bg_mv_electronic.jpg) center center/cover no-repeat;
}

.mv.type--03.product_outsourcing .mv__inner {
  background: url(../../common/img/product/bg_mv_outsourcing.jpg) center center/cover no-repeat;
}

.mv.type--03.product--coating .mv__inner {
  background: url(../../common/img/product_coating/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.product--plastic .mv__inner {
  background: url(../../common/img/product_plastic/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.product--electronic .mv__inner {
  background: url(../../common/img/product_electronic/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.product--outsourcing .mv__inner {
  background: url(../../common/img/product_outsourcing/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.news .mv__inner {
  background: url(../../common/img/news/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.contact .mv__inner {
  background: url(../../common/img/contact/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.privacypolicy .mv__inner {
  background: url(../../common/img/privacypolicy/bg_mv.jpg) center center/cover no-repeat;
}

.mv.type--03.english .mv__inner {
  background: url(../../common/img/english/bg_mv.jpg) center center/cover no-repeat;
}

#contents.top .about--us {
  background: #fff;
}

#contents.top .about--us .text--area {
  margin-top: 60px;
  text-align: center;
}

#contents.top .about--us .text--area .chatch {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1em;
  line-height: 1.5em;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #contents.top .about--us .text--area .chatch {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
    line-height: 1.5em;
  }
}

#contents.top .about--us .text--area .text {
  margin-top: 62px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  #contents.top .about--us .text--area .text {
    text-align: left;
  }
}

#contents.top .about--us .link--list {
  display: flex;
  justify-content: space-between;
  max-width: 840px;
  margin: 80px auto 0;
}

@media screen and (max-width: 767px) {
  #contents.top .about--us .link--list {
    flex-direction: column;
  }
}

#contents.top .about--us .link--list .detail--btn {
  width: 30%;
  min-width: 0;
}

@media screen and (max-width: 767px) {
  #contents.top .about--us .link--list .detail--btn {
    width: 100%;
  }
  #contents.top .about--us .link--list .detail--btn + .detail--btn {
    margin-top: 30px;
  }
}

#contents.top .philosophy {
  background: url(../../common/img/index/bg_philosophy.jpg) center center/cover no-repeat;
}

#contents.top .philosophy .block--link {
  display: block;
  width: 100%;
  height: 100%;
}

#contents.top .philosophy .text--area {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  text-align: center;
  color: #fff;
  padding-bottom: 50px;
}

#contents.top .philosophy .text--area .text {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1em;
  line-height: 1.8em;
}

#contents.top .philosophy .text--area .since {
  margin-top: 60px;
  position: relative;
}

#contents.top .philosophy .text--area .since .ttl {
  font-size: 110px;
  font-size: 11rem;
  line-height: 1em;
  font-size: 7.333333vw;
}

@media screen and (max-width: 767px) {
  #contents.top .philosophy .text--area .since .ttl {
    font-size: 50px;
    font-size: 5rem;
    line-height: 1em;
  }
}

#contents.top .philosophy .text--area .since .ttl span {
  font-size: 6vw;
}

@media screen and (max-width: 767px) {
  #contents.top .philosophy .text--area .since .ttl span {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
  }
}

#contents.top .philosophy .text--area .since__detail {
  width: calc(420 / 1500 * 100vw);
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  #contents.top .philosophy .text--area .since__detail {
    width: 100%;
    margin-top: 70px;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    transform: none;
  }
}

#contents.top .philosophy .text--area .since__detail::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

#contents.top .philosophy .text--area .since__detail::after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 auto;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#contents.top .philosophy .text--area .since__detail .txt {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#contents.top .philosophy .text--area .since__detail .txt__inner {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1em;
  width: 5em;
  position: absolute;
  top: calc(50% + 34px);
  left: 50%;
  transform: translate(-50%, -50%);
}

#contents.top .philosophy .text--area .since__detail .txt .year {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1em;
}

#contents.top .philosophy .text--area .since__detail .txt::before, #contents.top .philosophy .text--area .since__detail .txt::after {
  content: "";
  display: block;
  background: #fff;
}

#contents.top .philosophy .text--area .since__detail .txt::before {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 auto;
}

#contents.top .philosophy .text--area .since__detail .txt::after {
  width: 1px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#contents.top .philosophy .text--area .since__detail .sub {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-140%);
}

#contents.top .philosophy .text--area .since__detail .sub span {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1em;
}

#contents.top .philosophy__list {
  width: calc(450 / 1500 * 100vw);
  font-family: 游明朝, "Yu Mincho", YuMincho, "Noto Serif JP", "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #fff;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 900px) {
  #contents.top .philosophy__list {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  #contents.top .philosophy__list {
    width: 95%;
  }
}

#contents.top .philosophy__list::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0;
}

@media screen and (max-width: 767px) {
  #contents.top .philosophy__list::before {
    width: 93%;
  }
}

#contents.top .philosophy__list::after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 auto;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
}

#contents.top .philosophy__list li {
  position: relative;
}

#contents.top .philosophy__list li + li {
  margin-left: 8%;
}

#contents.top .philosophy__list li::before, #contents.top .philosophy__list li::after {
  content: "";
  display: block;
  background: #fff;
}

#contents.top .philosophy__list li::before {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin: 0 auto;
}

#contents.top .philosophy__list li::after {
  width: 1px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#contents.top .philosophy__list li p {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1em;
  margin-top: 18px;
  text-align: center;
}

#contents.top .philosophy__list li p span {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1em;
}

#contents.top .service {
  background: #fff;
}

#contents.top .service .sec--cmn__ttl .jp {
  color: #000;
}

#contents.top .service__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  #contents.top .service__list {
    flex-wrap: wrap;
  }
}

#contents.top .service__list li {
  width: 17%;
}

@media screen and (max-width: 767px) {
  #contents.top .service__list li {
    width: 48%;
  }
  #contents.top .service__list li:nth-child(n+3) {
    margin-top: 30px;
  }
}

#contents.top .service__list li figure .fig {
  width: 100%;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

#contents.top .service__list li figure figcaption {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.3em;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin-top: 22px;
}

@media screen and (max-width: 767px) {
  #contents.top .service__list li figure figcaption {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.5em;
    margin-top: 20px;
  }
}

#contents.top .service .text {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  line-height: 1.3em;
  text-align: center;
  font-weight: bold;
  color: #000;
  margin-top: 76px;
}

#contents.top .service .detail--btn {
  display: block;
  max-width: 383px;
  width: 80%;
  margin: 56px auto 0;
}

#contents.top .product::after {
  background: #fff;
}

#contents.top .product .block--type__inner {
  background: #000;
  padding-bottom: 52px;
}

#contents.top .product .text {
  width: 80%;
  max-width: 1024px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  line-height: 1.8em;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 52px auto 0;
}

#contents.top .product__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #000;
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #contents.top .product__list {
    flex-direction: column;
  }
}

#contents.top .product__list li {
  width: 33.333333%;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding-bottom: 100px;
  position: relative;
}

#contents.top .product__list li + li {
  border-left: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  #contents.top .product__list li {
    width: 100%;
  }
  #contents.top .product__list li + li {
    margin-top: 30px;
    border: none;
  }
}

#contents.top .product__list li::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: calc(294 / 500 * 100%);
}

@media screen and (max-width: 767px) {
  #contents.top .product__list li::before {
    padding-top: calc(294 / 375 * 100%);
  }
}

#contents.top .product__list li:nth-child(1)::before {
  background: url(../../common/img/index/product_coating.jpg) center center/cover no-repeat;
}

#contents.top .product__list li:nth-child(2)::before {
  background: url(../../common/img/index/product_plastic.jpg) center center/cover no-repeat;
}

#contents.top .product__list li:nth-child(3)::before {
  background: url(../../common/img/index/product_electronic.jpg) center center/cover no-repeat;
}

#contents.top .product__list li .name {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1em;
  margin-top: 25px;
}

#contents.top .product__list li .sub {
  width: 94%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.5em;
  margin: 25px auto 0;
}

#contents.top .product__list li .detail--btn {
  width: 70%;
  margin-top: 27px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#contents.top .news {
  background: #F4F4F4;
}

#contents.top .news::after {
  display: none;
}

#contents.top .news__list {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #contents.top .news__list {
    width: 100%;
  }
}

#contents.top .news__list__inner {
  background: #fff;
  padding: 2% 3%;
}

#contents.top .news__list__inner li {
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  #contents.top .news__list__inner li {
    padding: 20px 0;
  }
}

#contents.top .news__list__inner li + li {
  border-top: 1px solid #000;
}

#contents.top .news__list__inner li .date {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
}

#contents.top .news__list__inner li .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  margin-left: 16px;
}

@media screen and (max-width: 767px) {
  #contents.top .news__list__inner li .text {
    display: block;
    margin-top: 14px;
    margin-left: 0;
  }
}

#contents.top .news__list__inner li .tag {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  background: #e60012;
  margin-left: 16px;
  padding: 4px 10px;
}

#contents.top .news__list .view--all {
  width: 184px;
  text-align: center;
  font-weight: bold;
  position: relative;
  border: none;
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  #contents.top .news__list .view--all {
    width: 100%;
  }
}

#contents.top .news__list .view--all a {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1em;
  color: #000;
  background: #D5D5D5;
  padding: 22px 0;
}

#contents.top .news__list .view--all a::after {
  content: "";
  display: block;
  width: 25px;
  height: 14px;
  background: url(../../common/img/common/direction.svg) center center/cover no-repeat;
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
}

#contents.philosophy .about--us {
  background: #000;
}

#contents.philosophy .about--us .inner {
  display: flex;
  justify-content: space-between;
}

#contents.philosophy .about--us .text--area {
  width: calc(612 / 1024 * 100%);
  color: #fff;
}

@media screen and (max-width: 767px) {
  #contents.philosophy .about--us .text--area {
    width: 100%;
  }
}

#contents.philosophy .about--us .text--area .fig {
  margin: 20px 0;
}

#contents.philosophy .about--us .text--area .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 2em;
}

#contents.philosophy .about--us .text--area .text + .text {
  margin-top: 30px;
}

#contents.philosophy .about--us .text--area .name {
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 30px;
}

#contents.philosophy .about--us .text--area .name p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  margin-right: 20px;
  margin-bottom: 12px;
}

#contents.philosophy .about--us .text--area .name img {
  width: 40%;
}

#contents.philosophy .about--us .fig--list {
  width: calc(370 / 1024 * 100%);
}

#contents.philosophy .about--us .fig--list p + p {
  margin-top: 30px;
}

#contents.philosophy .management {
  background: url(../../common/img/philosophy/bg_management.jpg) center center/cover no-repeat;
}

#contents.philosophy .management .inner {
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #contents.philosophy .management .inner {
    padding-bottom: 40px;
  }
}

#contents.philosophy .management .sec--cmn__ttl {
  position: relative;
  top: -16px;
  margin-top: 16px;
}

#contents.philosophy .environmental {
  background: url(../../common/img/philosophy/bg_environmental.jpg) center center/cover no-repeat;
  padding-bottom: 160px;
}

@media screen and (max-width: 767px) {
  #contents.philosophy .environmental {
    padding-bottom: 60px;
  }
}

#contents.philosophy .management .sec--cmn__ttl .jp,
#contents.philosophy .environmental .sec--cmn__ttl .jp {
  letter-spacing: 4px;
}

#contents.philosophy .management .text--area,
#contents.philosophy .environmental .text--area {
  margin-top: 63px;
}

@media screen and (max-width: 767px) {
  #contents.philosophy .management .text--area,
  #contents.philosophy .environmental .text--area {
    margin-top: 20px;
  }
}

#contents.philosophy .management .text--area p,
#contents.philosophy .environmental .text--area p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  text-align: center;
  line-height: 2.5em;
  color: #fff;
}

#contents.philosophy .management .text--area p + p,
#contents.philosophy .environmental .text--area p + p {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  #contents.service .sec--cmn {
    padding: 20px 0;
  }
}

#contents.service .point--01 .chatch {
  background: #B43660;
}

#contents.service .point--02 .chatch {
  background: #2A896C;
}

#contents.service .point--03 .chatch {
  background: #2A546A;
}

#contents.service .point--04 .chatch {
  background: #5E842A;
}

#contents.service .point--05 .chatch {
  background: #334A7E;
}

#contents.service .double .inner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #contents.service .double .inner {
    display: block;
  }
}

#contents.service .double .double--point {
  width: 44%;
}

@media screen and (max-width: 767px) {
  #contents.service .double .double--point {
    width: 100%;
  }
}

#contents.service .double .double--point .chatch {
  font-weight: bold;
  text-align: center;
  color: #fff;
  padding: 30px 0;
  box-sizing: border-box;
}

#contents.service .double .double--point .chatch .fig {
  width: 100px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #contents.service .double .double--point .chatch .fig {
    width: 75px;
  }
}

#contents.service .double .double--point .chatch .number {
  font-size: 38px;
  font-size: 3.8rem;
  line-height: 1em;
  line-height: 1.5em;
  display: inline-block;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #contents.service .double .double--point .chatch .number {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
    line-height: 1.5em;
    margin-top: 14px;
  }
}

#contents.service .double .double--point .chatch .number::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: #707070;
}

#contents.service .double .double--point .chatch h2 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1em;
  line-height: 1.5em;
  margin-top: 14px;
}

@media screen and (max-width: 767px) {
  #contents.service .double .double--point .chatch h2 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1em;
    line-height: 1.5em;
  }
}

#contents.service .double .double--point .chatch h2.type-01 {
  padding: 20px 0;
}

#contents.service .double .double--point .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  margin-top: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #contents.service .double .double--point .text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.8em;
  }
}

#contents.service .single .chatch {
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: #2A2728;
  padding: 30px 0;
  box-sizing: border-box;
}

#contents.service .single .chatch .fig {
  width: 100px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #contents.service .single .chatch .fig {
    width: 75px;
  }
}

#contents.service .single .chatch .number {
  font-size: 38px;
  font-size: 3.8rem;
  line-height: 1em;
  line-height: 1.5em;
  display: inline-block;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #contents.service .single .chatch .number {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1em;
    line-height: 1.5em;
    margin-top: 14px;
  }
}

#contents.service .single .chatch .number::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: #707070;
}

#contents.service .single .chatch h2 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1em;
  line-height: 1.5em;
  margin-top: 14px;
}

@media screen and (max-width: 767px) {
  #contents.service .single .chatch h2 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1em;
    line-height: 1.5em;
  }
}

#contents.service .single .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  margin-top: 20px;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  #contents.service .single .text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.8em;
  }
}

#contents.company .profile {
  background: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  color: #000;
}

@media screen and (max-width: 767px) {
  #contents.company .profile {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.5em;
  }
}

#contents.company .profile table {
  display: block;
  width: 100%;
}

#contents.company .profile table tbody {
  width: 100%;
  display: block;
}

#contents.company .profile table tr {
  width: 100%;
  display: flex;
}

#contents.company .profile table tr:nth-child(2n-1) {
  background: #e3e3e3;
}

#contents.company .profile table tr:nth-child(2n) {
  color: #000;
}

#contents.company .profile table tr th {
  width: 30%;
  padding: 4px 4%;
  display: inline-block;
}

#contents.company .profile table tr td {
  width: 70%;
  padding: 4px 4%;
  display: inline-block;
}

#contents.company .history {
  background: url(../../common/img/company/bg_history.jpg) left center/cover no-repeat;
}

#contents.company .history .inner {
  background: #000;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  color: #fff;
  margin-top: 30px;
  padding: 67px 32px 27px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  #contents.company .history .inner {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.5em;
    padding: 30px 10px 20px;
  }
}

#contents.company .history table {
  display: block;
  padding: 0 0 0 8%;
}

@media screen and (max-width: 767px) {
  #contents.company .history table {
    padding: 0;
  }
}

#contents.company .history table tbody {
  width: 100%;
}

#contents.company .history table tr {
  display: flex;
}

#contents.company .history table tr + tr {
  margin-top: 28px;
}

#contents.company .history table tr th {
  padding-right: 36px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  #contents.company .history table tr th {
    padding-right: 20px;
  }
}

#contents.company .history table tr th::after {
  content: "";
  display: block;
  width: 32px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 13px;
  right: 0;
}

@media screen and (max-width: 767px) {
  #contents.company .history table tr th::after {
    width: 16px;
    top: 10px;
  }
}

#contents.company .history table tr td {
  display: inline-block;
  padding-left: 10px;
}

#contents.company .history .fig--list {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #contents.company .history .fig--list {
    flex-wrap: wrap;
  }
}

#contents.company .history .fig--list li {
  width: 25%;
}

@media screen and (max-width: 767px) {
  #contents.company .history .fig--list li {
    width: 50%;
  }
}

#contents.company .access {
  background: #000;
  padding-bottom: 0;
}

#contents.company .access__cmn {
  width: 100%;
  background: #2A2728;
}

@media screen and (max-width: 767px) {
  #contents.company .access__cmn + .access__cmn {
    padding-top: 30px;
  }
}

#contents.company .access__cmn__inner {
  width: 90%;
  max-width: 890px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  #contents.company .access__cmn__inner {
    width: 95%;
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  #contents.company .access__cmn__inner {
    width: 90%;
    display: block;
  }
}

#contents.company .access__cmn__inner .text--area {
  width: 50%;
  color: #fff;
}

@media screen and (max-width: 900px) {
  #contents.company .access__cmn__inner .text--area {
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  #contents.company .access__cmn__inner .text--area {
    width: 100%;
  }
}

#contents.company .access__cmn__inner .text--area figure {
  width: 100%;
  margin-top: 20px;
}

#contents.company .access__cmn__inner .text--area .name {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  line-height: 1.3em;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #contents.company .access__cmn__inner .text--area .name {
    padding-top: 30px;
  }
  #contents.company .access__cmn__inner .text--area .name.border {
    border-top: 2px solid #fff;
  }
}

#contents.company .access__cmn__inner .text--area .address {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.5em;
  margin-top: 30px;
}

@media screen and (max-width: 900px) {
  #contents.company .access__cmn__inner .text--area .address {
    margin-top: 20px;
  }
}

#contents.company .access__cmn__inner figure {
  width: 50%;
}

@media screen and (max-width: 900px) {
  #contents.company .access__cmn__inner figure {
    width: 60%;
  }
}

#contents.company .access__cmn .map {
  width: 100%;
  height: calc(462 / 1500 * 100vw);
  background: #000;
}

@media screen and (max-width: 767px) {
  #contents.company .access__cmn .map {
    height: calc(300 / 375 * 100vw);
    margin-top: 10px;
  }
}

#contents.company .access__cmn .map iframe {
  width: 100%;
  height: 100%;
}

#contents.product .list--area {
  background: #2A2728;
}

#contents.product .list--area .text--area {
  width: 90%;
  max-width: 856px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  line-height: 1.8em;
  color: #fff;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .text--area {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.8em;
  }
}

#contents.product .list--area .list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 70px;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list {
    flex-wrap: wrap;
  }
}

#contents.product .list--area .list li {
  width: 49%;
  border: 1px solid #707070;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list li {
    width: 100%;
    margin-top: 20px;
  }
}

#contents.product .list--area .list li:nth-child(n+3) {
  margin-top: 2%;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list li:nth-child(n+3) {
    margin-top: 20px;
  }
}

#contents.product .list--area .list li figure img {
  width: 100%;
}

#contents.product .list--area .list li figure figcaption {
  width: 80%;
  margin: 37px auto;
  padding-top: 100px;
  position: relative;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list li figure figcaption {
    margin: 20px auto;
  }
}

#contents.product .list--area .list li figure figcaption .text {
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list li figure figcaption .text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.8em;
  }
}

#contents.product .list--area .list li figure figcaption .detail--btn {
  display: block;
  width: 80%;
  margin: 45px auto 0;
}

@media screen and (max-width: 767px) {
  #contents.product .list--area .list li figure figcaption .detail--btn {
    margin-top: 0;
  }
}

#contents.product--coating .text--area {
  padding: 50px 0;
}

#contents.product--coating .story--top {
  padding: 0;
}

#contents.product--coating .story--top .fig {
  width: 100%;
  background: url(../../common/img/product_coating/img_01.png) center center/cover no-repeat;
}

#contents.product--coating .story--01 .fig {
  width: 100%;
  background: url(../../common/img/product_coating/img_02.png) center center/cover no-repeat;
}

#contents.product--coating .story--02 .fig {
  width: 100%;
  background: url(../../common/img/product_coating/img_03.png) center center/cover no-repeat;
}

#contents.product--plastic .text--area {
  padding: 50px 0;
}

#contents.product--plastic .story--top {
  padding: 0;
}

#contents.product--plastic .story--top .fig {
  width: 100%;
  background: url(../../common/img/product_plastic/img_01.png) center center/cover no-repeat;
}

#contents.product--plastic .story--01 .fig {
  width: 100%;
  background: url(../../common/img/product_plastic/img_02.png) center center/cover no-repeat;
}

#contents.product--plastic .story--02 .fig {
  width: 100%;
  background: url(../../common/img/product_plastic/img_03.png) center center/cover no-repeat;
}

#contents.product--electronic .text--area {
  padding: 50px 0;
}

#contents.product--electronic .story--top {
  background: url(../../common/img/product_electronic/bg_story.jpg) center center/cover no-repeat;
}

#contents.product--electronic .story--01 .fig {
  width: 100%;
  background: url(../../common/img/product_electronic/img_02.png) center center/cover no-repeat;
}

#contents.product--outsourcing .story--top {
  background: url(../../common/img/product_outsourcing/bg_story.jpg) center center/cover no-repeat;
}

#contents.product--cmn .story--top.story--top--01 {
  background: #2A2728;
}

#contents.product--cmn .story--top__ttl {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1em;
  line-height: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

#contents.product--cmn .story--top__ttl::after {
  content: "";
  display: block;
  width: 32px;
  height: 4px;
  background: #fff;
  margin: 50px auto 0;
}

#contents.product--cmn .story--top .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 2em;
  color: #fff;
  margin-top: 28px;
}

#contents.product--cmn .story--area {
  width: 100%;
  background: #000;
  padding: 0;
}

#contents.product--cmn .story--top--01 .story--top__inner,
#contents.product--cmn .story--cmn {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top--01 .story--top__inner,
  #contents.product--cmn .story--cmn {
    display: block;
  }
}

#contents.product--cmn .story--top--01 .story--top__inner:nth-child(1),
#contents.product--cmn .story--cmn:nth-child(1) {
  margin-left: 0;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top--01 .story--top__inner:nth-child(1),
  #contents.product--cmn .story--cmn:nth-child(1) {
    margin: auto;
  }
}

#contents.product--cmn .story--top--01 .story--top__inner:nth-child(2),
#contents.product--cmn .story--cmn:nth-child(2) {
  margin-left: auto;
  margin-right: 0;
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top--01 .story--top__inner:nth-child(2),
  #contents.product--cmn .story--cmn:nth-child(2) {
    margin: 40px auto 0;
    padding-bottom: 60px;
  }
}

#contents.product--cmn .story--top--01 .story--top__inner .fig,
#contents.product--cmn .story--cmn .fig {
  width: calc(662 / 1262 * 100%);
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top--01 .story--top__inner .fig,
  #contents.product--cmn .story--cmn .fig {
    width: 100%;
  }
}

#contents.product--cmn .story--top--01 .story--top__inner .text--area,
#contents.product--cmn .story--cmn .text--area {
  width: calc(542 / 1262 * 100%);
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top--01 .story--top__inner .text--area,
  #contents.product--cmn .story--cmn .text--area {
    width: 100%;
  }
}

#contents.product--cmn .story--top--01 .story--top__inner .text--area .ttl,
#contents.product--cmn .story--cmn .text--area .ttl {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-weight: bold;
  color: #000;
  display: inline-block;
  background: #fff;
  padding: 15px 48px;
}

#contents.product--cmn .story--top--01 .story--top__inner .text--area .catch,
#contents.product--cmn .story--cmn .text--area .catch {
  font-size: 33px;
  font-size: 3.3rem;
  line-height: 1em;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 35px;
}

#contents.product--cmn .story--top--01 .story--top__inner .text--area .catch::after,
#contents.product--cmn .story--cmn .text--area .catch::after {
  content: "";
  display: block;
  width: 60%;
  height: 1px;
  background: #fff;
  margin: 35px auto 0;
}

#contents.product--cmn .story--top--01 .story--top__inner .text--area .text,
#contents.product--cmn .story--cmn .text--area .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  text-align: left;
  margin-top: 40px;
}

#contents.product--cmn .story--top__inner {
  margin-left: auto !important;
  margin-right: 0 !important;
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .story--top__inner {
    margin: 0 auto !important;
  }
}

#contents.product--cmn .story--top__inner .text--area .catch::after {
  width: 32px !important;
  height: 3px !important;
}

#contents.product--cmn .product--item {
  background: #2A2728;
}

#contents.product--cmn .product--item.type--01 {
  padding-top: 188px;
}

@media screen and (max-width: 767px) {
  #contents.product--cmn .product--item.type--01 {
    padding-top: 100px;
  }
}

#contents.product--cmn .product--item .inner {
  background: #000;
  padding: 48px 4%;
  z-index: 1;
  position: relative;
}

#contents.product--cmn .product--item .bg--area {
  margin-top: 20px;
}

#contents.product--cmn .product--item .ttl {
  text-align: center;
}

#contents.product--cmn .product--item .ttl .en {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-weight: bold;
  color: #000;
  background: #fff;
  display: inline-block;
  padding: 13px 47px;
}

#contents.product--cmn .product--item .ttl .jp {
  font-size: 33px;
  font-size: 3.3rem;
  line-height: 1em;
  line-height: 1.5em;
  font-weight: bold;
  color: #fff;
  display: block;
  margin-top: 36px;
}

#contents.product--cmn .product--item .ttl::after {
  content: "";
  display: block;
  width: 70%;
  max-width: 288px;
  height: 1px;
  background: #fff;
  margin: 45px auto 0;
}

#contents.product--cmn .product--item .product--list {
  width: 100%;
  margin-top: 48px;
}

#contents.product--cmn .product--item .product--list.center {
  text-align: center;
}

#contents.product--cmn .product--item .product--list li {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 2.5em;
  color: #fff;
}

#contents.news .news--list {
  background: #2A2728;
}

#contents.news .news--list .inner {
  background: #fff;
  padding: 50px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  #contents.news .news--list .inner {
    padding: 20px;
  }
}

#contents.news .news--list .inner .list li {
  padding: 10px 0;
}

#contents.news .news--list .inner .list li + li {
  border-top: 1px solid #000;
  margin-top: 20px;
}

#contents.news .news--list .inner .list li .date {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
}

#contents.news .news--list .inner .list li .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.3em;
  display: block;
  margin-top: 20px;
}

#contents.news .news--list .inner .list li .tag {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  background: #e60012;
  margin-left: 16px;
  padding: 4px 10px;
}

#contents.contact .form--area {
  color: #fff;
  background: #2A2728;
}

#contents.contact .form--area .inner {
  max-width: 800px;
}

#contents.contact .form--area .form--cmn + .form--cmn {
  margin-top: 40px;
}

#contents.contact .form--area .form--cmn.company--form.active {
  display: none;
}

#contents.contact .form--area .form--cmn .ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: bold;
}

#contents.contact .form--area .form--cmn .ttl.required::after {
  content: "必須";
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  background: #e60012;
  padding: 4px 8px;
  margin-left: 20px;
}

#contents.contact .form--area .form--cmn .radio__inner {
  display: flex;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #contents.contact .form--area .form--cmn .radio__inner {
    display: block;
  }
}

#contents.contact .form--area .form--cmn .radio__inner p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 767px) {
  #contents.contact .form--area .form--cmn .radio__inner p {
    display: block;
  }
}

#contents.contact .form--area .form--cmn .radio__inner p + p {
  margin-left: 4%;
}

@media screen and (max-width: 767px) {
  #contents.contact .form--area .form--cmn .radio__inner p + p {
    margin-left: 0;
    margin-top: 20px;
  }
}

#contents.contact .form--area .form--cmn .radio__inner p label {
  padding-left: 22px;
}

#contents.contact .form--area .form--cmn .radio__inner p label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#contents.contact .form--area .form--cmn .radio__inner p input {
  display: none;
}

#contents.contact .form--area .form--cmn .radio__inner p input:checked + label::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}

#contents.contact .form--area .form--cmn .input--text {
  margin-top: 20px;
}

#contents.contact .form--area .form--cmn .input--text input, #contents.contact .form--area .form--cmn .input--text textarea {
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

#contents.contact .form--area .submit {
  width: 80%;
  max-width: 300px;
  background: white;
  margin: 65px auto 0;
}

#contents.contact .form--area .submit input {
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  text-align: center;
  color: #000;
  background: #fff;
  border: none;
  padding: 20px 0;
  cursor: pointer;
}

#contents.contact .confirmation--area {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  color: #fff;
  background: #2A2728;
}

#contents.contact .confirmation--area .inner {
  max-width: 508px;
}

#contents.contact .confirmation--area .confirmation--cmn .text {
  margin-top: 20px;
}

#contents.contact .confirmation--area .confirmation--cmn + .confirmation--cmn {
  margin-top: 40px;
}

#contents.contact .confirmation--area .completion--area {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

#contents.contact .confirmation--area .completion--area form {
  width: 45%;
}

#contents.contact .confirmation--area .completion--area form input {
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  font-family: "Noto Sans", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 16px 0;
  border: none;
  cursor: pointer;
}

#contents.contact .completion--area {
  background: #2A2728;
}

#contents.contact .completion--area .inner {
  max-width: 580px;
}

#contents.contact .completion--area .inner .ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-top: 20px;
}

#contents.contact .completion--area .inner .text {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  font-weight: bold;
  color: #fff;
  margin-top: 40px;
}

#contents.contact .completion--area .inner .link {
  width: 100%;
  max-width: 280px;
  margin: 120px auto 60px;
}

#contents.contact .completion--area .inner .link a {
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  text-align: center;
  font-weight: bold;
  color: #000;
  background: #fff;
  padding: 30px 0;
}

#contents.privacypolicy .privacypolicy--sec {
  background: #2A2728;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.5em;
  color: #fff;
}

#contents.privacypolicy .privacypolicy--sec .ttl {
  margin-top: 40px;
}

#contents.privacypolicy .privacypolicy--sec .text {
  margin-top: 20px;
}

#contents.privacypolicy .privacypolicy--sec .list {
  margin-top: 20px;
  margin-left: 1em;
}

#contents.privacypolicy .privacypolicy--sec .list li {
  counter-increment: number 1;
}

#contents.privacypolicy .privacypolicy--sec .list li::before {
  content: "（" counter(number) "）";
}

#contents.privacypolicy .privacypolicy--sec .list li + li {
  margin-top: 10px;
}

#contents.privacypolicy .privacypolicy--sec .list__text__list {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 1em;
}

#contents.privacypolicy .privacypolicy--sec .list__text__list__text__list {
  margin-top: 20px;
  margin-left: 1em;
}

#contents.privacypolicy .privacypolicy--sec ul {
  margin-top: 20px;
}

#contents.privacypolicy .privacypolicy--sec ul li + li {
  margin-top: 10px;
}

#contents.english .sec--cmn__ttl::after {
  max-width: 286px;
  width: 80%;
  height: 1px;
  margin: 50px auto;
}

@media screen and (max-width: 767px) {
  #contents.english .sec--cmn__ttl::after {
    margin: 30px auto;
  }
}

#contents.english .profile {
  background: #000;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.8em;
  color: #000;
}

@media screen and (max-width: 767px) {
  #contents.english .profile {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1em;
    line-height: 1.5em;
  }
}

#contents.english .profile table {
  display: block;
  width: 100%;
}

#contents.english .profile table tbody {
  width: 100%;
  display: block;
}

#contents.english .profile table tr {
  width: 100%;
  display: flex;
}

#contents.english .profile table tr th {
  width: 30%;
  color: #fff;
  padding: 4px 4%;
  display: inline-block;
}

#contents.english .profile table tr td {
  width: 70%;
  color: #fff;
  padding: 4px 4%;
  display: inline-block;
}

#contents.english .management, #contents.english .management02 {
  background: #2A2728;
}

#contents.english .management .inner, #contents.english .management02 .inner {
  padding-bottom: 80px;
}

@media screen and (max-width: 767px) {
  #contents.english .management .inner, #contents.english .management02 .inner {
    padding-bottom: 40px;
  }
}

#contents.english .management .sec--cmn__ttl, #contents.english .management02 .sec--cmn__ttl {
  position: relative;
  top: -16px;
  margin-top: 16px;
}

#contents.english .management .text--area, #contents.english .management02 .text--area {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.5em;
  color: #fff;
}

#contents.english .management .text--area p + p, #contents.english .management02 .text--area p + p {
  margin-top: 24px;
}

#contents.english .management02 {
  background: #000;
}

#contents.english .access {
  background: #2A2728;
}

#contents.english .access__cmn {
  width: 100%;
  background: #2A2728;
}

#contents.english .access__cmn + .access__cmn {
  padding-top: 80px;
}

#contents.english .access__cmn__inner {
  width: 100%;
}

#contents.english .access__cmn__inner .text--area {
  width: 100%;
  color: #fff;
}

#contents.english .access__cmn__inner .text--area a {
  color: #fff;
  text-decoration: underline;
}

#contents.english .access__cmn__inner .text--area .name {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1em;
  line-height: 1.3em;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #contents.english .access__cmn__inner .text--area .name {
    padding-top: 30px;
  }
  #contents.english .access__cmn__inner .text--area .name.border {
    border-top: 2px solid #fff;
  }
}

#contents.english .access__cmn__inner .text--area .address {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1em;
  line-height: 1.5em;
  margin-top: 30px;
}

@media screen and (max-width: 900px) {
  #contents.english .access__cmn__inner .text--area .address {
    margin-top: 20px;
  }
}

#contents.english .access__cmn .map {
  width: 100%;
  height: calc(462 / 1500 * 100vw);
  background: #000;
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  #contents.english .access__cmn .map {
    height: calc(300 / 375 * 100vw);
    margin-top: 20px;
  }
}

#contents.english .access__cmn .map iframe {
  width: 100%;
  height: 100%;
}
