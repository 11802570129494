#contents {
  &.news {
    .news--list {
      background: $black02;
      .inner {
        background: $white;
        padding: 50px;
        box-sizing: border-box;
        @include sp {
          padding: 20px;
        }
        .list {
          li {
            padding: 10px 0;
            & + li {
              border-top: 1px solid $black;
              margin-top: 20px;
            }
            .date {
              @include fz(16);
            }
            .text {
              @include fz(16);
              line-height: 1.3em;
              display: block;
              margin-top: 20px;
            }
            .tag {
              @include fz(14);
              font-weight: bold;
              color: $white;
              background: $red;
              margin-left: 16px;
              padding: 4px 10px;
            }
          }
        }
      }
    }
  }
}
