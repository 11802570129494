#contents {
  &.philosophy {
// ==============================================================================================================
//▼ about--us
//---------------------------------------------------------------------------------------------------------------
    .about--us {
      background: $black;
      .inner {
        display: flex;
        justify-content: space-between;
      }
      .text--area {
        width: calc(612 / 1024 * 100%);
        color: $white;
        @include sp {
          width: 100%;
        }
        .fig {
          margin: 20px 0;
        }
        .text {
          @include fz(16);
          line-height: 2em;
          & + .text {
            margin-top: 30px;
          }
        }
        .name {
          text-align: right;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-top: 30px;
          p {
            @include fz(18);
            margin-right: 20px;
            margin-bottom: 12px;
          }
          img {
            width: 40%;
          }
        }
      }
      .fig--list {
        width: calc(370 / 1024 * 100%);
        p {
          & + p {
            margin-top: 30px;
          }
        }
      }
    }
// ==============================================================================================================
//▼ management
//---------------------------------------------------------------------------------------------------------------
    .management {
      background: url(../../common/img/philosophy/bg_management.jpg) center center /cover no-repeat;
      .inner {
        // background: rgba(0,0,0,0.8);
        padding-bottom: 80px;
        @include sp {
          padding-bottom: 40px;
        }
      }
      .sec--cmn__ttl {
        position: relative;
        top: -16px;
        margin-top: 16px;
      }
    }

// ==============================================================================================================
//environmental
//---------------------------------------------------------------------------------------------------------------
    .environmental {
      background: url(../../common/img/philosophy/bg_environmental.jpg) center center /cover no-repeat;
      padding-bottom: 160px;
      @include sp {
        padding-bottom: 60px;
      }
    }

    .management,
    .environmental {
      .sec--cmn__ttl {
        .jp {
          letter-spacing: 4px;
        }
      }
      .text--area {
        margin-top: 63px;
        @include sp {
          margin-top: 20px;
        }
        p {
          @include fz(18);
          text-align: center;
          line-height: 2.5em;
          color: $white;
          & + p {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
