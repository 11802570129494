.global--nav {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  right: 0;
  color: $white;
  font-family: $font_noto;
  // font-weight: bold;
  z-index: 9999;
  transition: 0.4s all;
  &__inner {
    width: 100%;
    height: 100%;
    padding: 0 18px;
    box-sizing: border-box;
    background: $black;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    transition: 0.4s;
    z-index: 1;
  }
  &__text--area {
    width: calc(1193 / 1500 * 100%);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 18px 0;
    white-space: nowrap;
    overflow: hidden;
    transition: 0.4s;
    .logo {
      width: calc(150 / 1193 * 100%);
      font-weight: bold;
      text-align: right;
      margin-left: calc(30 / 1193 * 100%);
      @include sp {
        width: 60%;
        margin-left: 0;
      }
      a {
        color: $white;
        display: flex;
        justify-content: center;
      }
      span {
        display: block;
      }
      .mini {
        @include fz(12);
        line-height: 1.3em;
      }
      .big {
        @include fz(30);
        margin-left: 12px;
      }
    }
    .chatch {
      // width: calc(682 / 1193 * 100%);
      width: calc(600 / 1193 * 100%);
      @include fz(14);
    }
  }
  &__btn {
    width: calc(288 / 1500 * 100%);
    background: none;
    border: 1px solid $white;
    border-radius: 37px;
    height: 52px;
    // margin: auto 0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.4s;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    &:hover {
      cursor: pointer;
    }
    @include sp {
      width: 52px;
      border-radius: 50%;
      right: 9px;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 22px;
      height: 1px;
      background: $white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.4s all;
    }
    &::before {
      top: calc(50% - 12px);
    }
    &::after {
      top: calc(50% + 12px);
    }
    span {
      display: block;
      width: 22px;
      height: 1px;
      background: $white;
      margin: 8px auto;
      transition: 0.4s all;
    }
  }
  #navGlobal {
    width: 100%;
    height: 0;
    background: $black;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 0;
    // margin-top: 70px;
    box-sizing: border-box;
    overflow: hidden;
    transition: 0.4s all;
    .nav__inner {
      max-width: 752px;
      width: 90%;
      margin: 70px auto 68px;
      display: flex;
      justify-content: space-between;
      @include sp {
        width: 80%;
        display: block;
        margin: 0 auto 30px;
      }
    }
    .nav--fig {
      // width: 280px;
      width: calc(280 / 752 * 100%);
      // height: 100%;
      background-position: center center;
      background-size: cover;
      @include sp {
        width: 100%;
        padding-top: calc(144 / 280 * 100%);
      }
    }
    .flex--box {
      width: calc(432 / 752 * 100%);
      display: flex;
      justify-content: space-between;
      @include sp {
        width: 100%;
        display: block;
        margin-top: 20px;
      }
    }
    .nav--list {
      &:nth-child(1){
        width: calc(252 / 432 *100%);
        @include sp {
          width: 100%;
        }
      }
      &:nth-child(2){
        width: calc(180 / 432 *100%);
        @include sp {
          width: 100%;
          margin-top: 24px;
        }
      }
      .list {
        @include sp {
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 6px;
            height: 11px;
            background: url(../../common/img/common/nav_direction.svg) center center /cover no-repeat;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
        & + .list {
          margin-top: 16px;
          @include sp {
            margin-top: 24px;
          }
        }
        @include sp {
          &.none--link {
            &:before {
              display: none;
            }
          }
        }
        .inner--nav {
          @include sp {
            height: 0px;
            overflow: hidden;
            transition: 0.3s all;
          }
          &__list {
            margin-top: 14px;
            margin-left: 10px;
            padding-left: 20px;
            position: relative;
            &::before,
            &::after {
              content: "";
              display: block;
              background: $white;
              position: absolute;
              left: 0;
            }
            &::before {
              width: 8px;
              height: 1px;
              top: 50%;
              transform: translateY(-50%);
            }
            &::after {
              width: 1px;
              height: 8px;
              top: 0;
            }
          }
        }
      }
      .type--01 {
        margin-top: 50px;
        &__inner {
          .box--link {
            width: 100%;
            text-align: center;
            border: 1px solid $white;
            & + li {
              margin-top: 20px;
            }
            a {
              line-height: 1.5em;
              display: block;
              padding: 16px 0;
            }
          }
        }
      }
      a, .nav--link--none {
        @include fz(14);
        font-family: $font_noto;
        color: $white;
      }
      .nav--link--none {
        display: block;
        @include sp {
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 6px;
            height: 11px;
            background: url(../../common/img/common/nav_direction.svg) center center /cover no-repeat;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            transition: 0.3s all;
          }
          &.active{
            &::before {
              transform: translateY(-50%) rotate(-90deg);
            }
            & +.inner--nav {
              height: 120px;
            }
          }
        }
      }
      a:hover {
        color: #88D1FF;
        text-decoration: underline;
      }
    }
  }
  &.active {
    .global--nav__inner {
      width: 70px;
      padding: 0;
    }
    .global--nav__text--area {
      width: 0%;
    }
    .global--nav__btn {
      width: 52px;
      border-radius: 50%;
      right: 9px;
    }
  }
  &.open {
    .global--nav__inner {
      width: 100%;
      padding: 0 18px;
    }
    .global--nav__text--area {
      width: calc(1193 / 1500 * 100%);
    }
    .global--nav__btn {
      width: calc(288 / 1500 * 100%);
      border-radius: 37px;
      right: 18px;
      @include sp {
        width: 52px;
        border-radius: 50%;
        right: 9px;
      }
      &::before,
      &::after {
        margin: auto;
        // transform: translateX(-50%);
        left: 50%;
        top: 50%;
        position: absolute;
      }
      &::before {
        transform: translateX(-50%) rotate(135deg);
      }
      &::after {
        transform: translateX(-50%) rotate(-135deg);
      }
      span {
        width: 0;
      }
    }
    #navGlobal {
      height: 468px;
      padding-top: 70px;
      @include sp {
        height: 100vh;
        overflow: auto;
      }
    }
  }
}
