#contents {
  &.contact {
    .form--area {
      color: $white;
      background: $black02;
      .inner {
        max-width: 800px;
      }
      .form--cmn {
        & + .form--cmn {
          margin-top: 40px;
        }
        &.company--form.active {
          display: none;
        }
        .ttl {
          @include fz(18);
          font-weight: bold;
          &.required {
            &::after {
              content: "必須";
              @include fz(12);
              font-weight: bold;
              color: $white;
              background: $red;
              padding: 4px 8px;
              margin-left: 20px;
            }
          }
        }
        .radio__inner {
          display: flex;
          margin-top: 20px;
          @include sp {
            display: block;
          }
          p {
            @include fz(16);
            display: inline-block;
            position: relative;
            @include sp {
              display: block;
            }
            & + p {
              margin-left: 4%;
              @include sp {
              margin-left: 0;
              margin-top: 20px;
            }
            }
            label {
              padding-left: 22px;
              &::before{
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background: $white;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            input {
              display: none;
              &:checked + label::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: $black ;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
              }
            }
          }
        }
        .input--text {
          margin-top: 20px;
          input,textarea {
            font-family: $font_noto;
            display: block;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
          }
        }
      }
      .submit {
        width: 80%;
        max-width: 300px;
        background: white;
        margin: 65px auto 0;
        input {
          display: block;
          width: 100%;
          @include fz(18);
          font-family: $font_noto;
          font-weight: bold;
          text-align: center;
          color: $black;
          background: $white;
          border: none;
          padding: 20px 0;
          cursor: pointer;
        }
      }
    }
    .confirmation--area {
      .inner {
        max-width: 508px;
      }
      @include fz(18);
      color: $white;
      background: $black02;
      .confirmation--cmn {
        .text {
          margin-top: 20px;
        }
        & + .confirmation--cmn {
          margin-top: 40px;
        }
      }
      .completion--area {
        display: flex;
        justify-content: space-between;
        margin-top: 120px;
        form {
          width: 45%;
          input {
            display: block;
            width: 100%;
            @include fz(18);
            font-family: $font_noto;
            font-weight: bold;
            text-align: center;
            padding: 16px 0;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
    .completion--area {
      background: $black02;
      .inner {
        max-width: 580px;
        .ttl {
          @include fz(18);
          font-weight: bold;
          text-align: center;
          color: $white;
          margin-top: 20px;
        }
        .text {
          @include fz(16);
          line-height: 1.8em;
          font-weight: bold;
          color: $white;
          margin-top: 40px;
        }
        .link {
          width: 100%;
          max-width: 280px;
          margin: 120px auto 60px;
          a {
            display: block;
            width: 100%;
            @include fz(18);
            text-align: center;
            font-weight: bold;
            color: $black;
            background: $white;
            padding: 30px 0;
          }
        }
      }
    }
  }
}
