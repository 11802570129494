.mv {
  &.type--01 {
    width: 100%;
    height: 100vh;
    // background: url(../../common/img/index/bg_mv.jpg) center center /cover no-repeat;
    text-align: center;
    position: relative;
    &.mv_01 {
      background: url(../../common/img/index/bg_mv_01.jpg) center center /cover no-repeat;
    }
    &.mv_02 {
      background: url(../../common/img/index/bg_mv_02.jpg) center center /cover no-repeat;
    }
    .mv__slider {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      li {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 4s all;
        .scroll--down {
          a {
            @include fz(12);
            font-family: $font_yumin;
            color: $white;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            cursor: pointer;
            &::before {
              content: "";
              display: block;
              width: 20px;
              height: 20px;
              background: url(../../common/img/index/scrolldown.svg) center center /cover no-repeat;
              position: absolute;
              bottom: 40px;
              left: 50%;
              transform: translateX(-50%);
            }
            &::after {
              content: "";
              display: block;
              width: 1px;
              height: 40px;
              background: $white;
              margin: 30px auto 0;
            }
          }
        }
        &.active {
          opacity: 1;
        }
        &:nth-child(1){
          background: url(../../common/img/index/bg_mv.jpg) center center /cover no-repeat;

        }
        &:nth-child(2){
          background: url(../../common/img/index/bg_mv_02.jpg) center center /cover no-repeat;
          .scroll--down a{
            color: $black;
            &::before {
              background: url(../../common/img/index/scrolldown_white.svg) center center /cover no-repeat;
            }
            &::after {
              background: $black;
            }
          }
        }
      }
    }
    .mv__inner {
      width: 100%;
      height: 100%;
      // font-weight: bold;
      color: $white;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // background: #666;
      z-index: 10;
      position: relative;
      .mv__canvas {
        width: 100vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .company--name {
        display: flex;
        justify-content: center;
        span {
          display: block;
        }
        .mini {
          @include fz(13);
          line-height: 1.3em;
          @include sp {
            @include fz(11);
            line-height: 1.3em;
          }
        }
        .big {
          @include fz(32);
          margin-left: 12px;
          @include sp {
            @include fz(26);
          }
        }
      }
      .sub {
        @include fz(22);
        margin-top: 46px;
        @include sp {
          @include fz(18);
        }
      }
      .chatch {
        display: inline;
        // @include fz(38);
        font-size: calc(38 / 680 * 100vh);
        letter-spacing: 18px;
        line-height: 1.7em;
        font-family: $font_yumin;
        writing-mode: vertical-rl;
        text-align: left;
        margin-top: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include sp {
          @include fz(30);
          line-height: 1.7em;
          letter-spacing: 10px;
          margin-top: 10px;
        }
      }
    }
    // .scroll--down {
    //   a {
    //     @include fz(12);
    //     font-family: $font_yumin;
    //     color: $white;
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     z-index: 10;
    //     cursor: pointer;
    //     &::before {
    //       content: "";
    //       display: block;
    //       width: 20px;
    //       height: 20px;
    //       background: url(../../common/img/index/scrolldown.svg) center center /cover no-repeat;
    //       position: absolute;
    //       bottom: 40px;
    //       left: 50%;
    //       transform: translateX(-50%);
    //     }
    //     &::after {
    //       content: "";
    //       display: block;
    //       width: 1px;
    //       height: 40px;
    //       background: $white;
    //       margin: 30px auto 0;
    //     }
    //   }
    // }
  }
  &.type--02,
  &.type--03 {
    .mv__inner {
      width: 100%;
      height: calc(532 / 1500 * 100vw);
      position: relative;
      @include sp {
        height: 40vh;
      }
    }
    .mv__ttl {
      width: 100%;
      height: 168px;
      background: $black02;
      position: relative;
      @include sp {
        height: auto;
      }
      &.type--01 {
        height: 214px;
        @include sp {
          height: auto;
        }
      }
      &.type--02 {
        height: 272px;
        @include sp {
          height: auto;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 2px;
        height: 52px;
        background: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &__cmn {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        @include sp {
          position: relative;
          left: auto;
          transform: none;
        }
        span {
          display: block;
        }
        .en {
          display: inline-block;
          @include fz(18);
          background: $white;
          padding: 16px 36px;
        }
        .jp {
          @include fz(40);
          line-height: 1.5em;
          letter-spacing: 4px;
          font-family: $font_yumin;
          color: $white;
          margin-top: 12px;
          @include sp {
            width: 90%;
            @include fz(30);
            margin: 14px auto 0;
            padding-bottom: 30px;
          }
        }
      }
    }
    &.black {
      .mv__ttl {
        background: $black;
      }
    }
  }
  &.type--02 {
    width: 100%;
    .mv__inner {
      background: url(../../common/img/philosophy/bg_mv.jpg) center center /cover no-repeat;
      // &::after {
      //   content: "1946";
      //   display: block;
      //   font-weight: bold;
      //   font-size: calc(352 / 1500 * 100vw);
      //   line-height: 1em;
      //   color: rgba(255, 255, 255, 0.5);
      //   position: absolute;
      //   left: 50%;
      //   bottom: -4vw;
      //   transform: translateX(-50%);
      //   z-index: 10;
      //   @include sp {
      //     width: 100%;
      //     text-align: center;
      //     @include fz(140);
      //   }
      // }
      h2 {
        width: 50%;
        text-align: center;
        padding: 20px 0;
        background: rgba(0,0,0,0.7);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -0%);
        span {
          display: block;
        }
        .en {
          display: inline-block;
          @include fz(18);
          background: $white;
          padding: 4px 36px;
        }
        .jp {
          @include fz(40);
          line-height: 1.5em;
          font-family: $font_yumin;
          color: $white;
          margin-top: 12px;
          @include sp {
            @include fz(30);
          }
        }
      }
    }
  }
  &.type--03 {
    &.company {
      .mv__inner {
        background: url(../../common/img/company/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.service {
      .mv__inner {
        background: url(../../common/img/service/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.philosophy {
      .mv__inner {
        background: url(../../common/img/philosophy/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.product {
      .mv__inner {
        background: url(../../common/img/product/bg_mv_coating.jpg) center center /cover no-repeat;
      }
    }
    &.product_coating {
      .mv__inner {
        background: url(../../common/img/product/bg_mv_coating.jpg) center center /cover no-repeat;
      }
    }
    &.product_plastic {
      .mv__inner {
        background: url(../../common/img/product/bg_mv_plastic.jpg) center center /cover no-repeat;
      }
    }
    &.product_electronic {
      .mv__inner {
        background: url(../../common/img/product/bg_mv_electronic.jpg) center center /cover no-repeat;
      }
    }
    &.product_outsourcing {
      .mv__inner {
        background: url(../../common/img/product/bg_mv_outsourcing.jpg) center center /cover no-repeat;
      }
    }
    &.product--coating {
      .mv__inner {
        background: url(../../common/img/product_coating/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.product--plastic {
      .mv__inner {
        background: url(../../common/img/product_plastic/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.product--electronic {
      .mv__inner {
        background: url(../../common/img/product_electronic/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.product--outsourcing {
      .mv__inner {
        background: url(../../common/img/product_outsourcing/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.news {
      .mv__inner {
        background: url(../../common/img/news/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.contact {
      .mv__inner {
        background: url(../../common/img/contact/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.privacypolicy {
      .mv__inner {
        background: url(../../common/img/privacypolicy/bg_mv.jpg) center center /cover no-repeat;
      }
    }
    &.english {
      .mv__inner {
        background: url(../../common/img/english/bg_mv.jpg) center center /cover no-repeat;
      }
    }
  }
}
