#contents {
  &.product--coating {
    .text--area {
      padding: 50px 0;
    }
    .story--top {
      padding: 0;
      .fig {
        width: 100%;
        background: url(../../common/img/product_coating/img_01.png) center center /cover no-repeat;
      }
    }
    .story--01 .fig {
      width: 100%;
      background: url(../../common/img/product_coating/img_02.png) center center /cover no-repeat;
    }
    .story--02 .fig {
      width: 100%;
      background: url(../../common/img/product_coating/img_03.png) center center /cover no-repeat;
    }
  }
  &.product--plastic {
    .text--area {
      padding: 50px 0;
    }
    .story--top {
      padding: 0;
      .fig {
        width: 100%;
        background: url(../../common/img/product_plastic/img_01.png) center center /cover no-repeat;
      }
    }
    .story--01 .fig {
      width: 100%;
      background: url(../../common/img/product_plastic/img_02.png) center center /cover no-repeat;
    }
    .story--02 .fig {
      width: 100%;
      background: url(../../common/img/product_plastic/img_03.png) center center /cover no-repeat;
    }
  }
  &.product--electronic {
    .text--area {
      padding: 50px 0;
    }
    .story--top {
      background: url(../../common/img/product_electronic/bg_story.jpg) center center /cover no-repeat;
    }
    .story--01 .fig {
      width: 100%;
      background: url(../../common/img/product_electronic/img_02.png) center center /cover no-repeat;
    }
  }
  &.product--outsourcing {
    .story--top {
      background: url(../../common/img/product_outsourcing/bg_story.jpg) center center /cover no-repeat;
    }
  }
  &.product--cmn {
// ==============================================================================================================
//▼ story--top
//---------------------------------------------------------------------------------------------------------------
    .story--top {
      &.story--top--01 {
        background: $black02;
      }
      &__ttl {
        @include fz(30);
        line-height: 1.5em;
        font-weight: bold;
        text-align: center;
        color: $white;
        &::after {
          content: "";
          display: block;
          width: 32px;
          height: 4px;
          background: $white;
          margin: 50px auto 0;
        }
      }
      .text {
        @include fz(16);
        line-height: 2em;
        // font-weight: bold;
        // text-align: center;
        color: $white;
        margin-top: 28px;
      }
    }
// ==============================================================================================================
//▼ story--area
//---------------------------------------------------------------------------------------------------------------
    .story--area {
      width: 100%;
      background: $black;
      padding: 0;
    }
    .story--top--01 .story--top__inner,
    .story--cmn {
      width: 90%;
      // max-width: 1262px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      @include sp {
        display: block;
      }
      &:nth-child(1){
        margin-left: 0;
        margin-right: auto;
        @include sp {
          margin: auto;
        }
      }
      &:nth-child(2){
        margin-left: auto;
        margin-right: 0;
        flex-direction: row-reverse;
        @include sp {
          margin: 40px auto 0;
          padding-bottom: 60px;
        }
      }
      .fig {
        width: calc(662 / 1262 * 100%);
        @include sp {
          width: 100%;
        }
      }
      .text--area {
        width: calc(542 / 1262 * 100%);
        color: $white;
        text-align: center;
        @include sp {
          width: 100%;
        }
        .ttl {
          @include fz(14);
          font-weight: bold;
          color: $black;
          display: inline-block;
          background: $white;
          padding: 15px 48px;
        }
        .catch {
          @include fz(33);
          font-weight: bold;
          line-height: 1.5em;
          margin-top: 35px;
          &::after {
            content: "";
            display: block;
            width: 60%;
            height: 1px;
            background: $white;
            margin: 35px auto 0;
          }
        }
        .text {
          @include fz(16);
          line-height: 1.8em;
          text-align: left;
          margin-top: 40px;
        }
      }
    }

    .story--top__inner {
      margin-left: auto!important;
      margin-right: 0!important;
      flex-direction: row-reverse;
      @include sp {
        margin: 0 auto!important;
      }
      .text--area {
        .catch {
          &::after {
            width: 32px!important;
            height: 3px!important;
          }
        }
      }
    }
// ==============================================================================================================
//▼ product--item
//---------------------------------------------------------------------------------------------------------------
    .product--item {
      background: $black02;
      &.type--01 {
        padding-top: 188px;
        @include sp {
          padding-top: 100px;
        }
      }
      .inner {
        background: $black;
        padding: 48px 4%;
        z-index: 1;
        position: relative;
      }
      .bg--area {
        margin-top: 20px;
      }
      .ttl {
        text-align: center;
        .en {
          @include fz(14);
          font-weight: bold;
          color: $black;
          background: $white;
          display: inline-block;
          padding: 13px 47px;
        }
        .jp {
          @include fz(33);
          line-height: 1.5em;
          font-weight: bold;
          color: $white;
          display: block;
          margin-top: 36px;
        }
        &::after {
          content: "";
          display: block;
          width: 70%;
          max-width: 288px;
          height: 1px;
          background: $white;
          margin: 45px auto 0;
        }
      }
      .product--list {
        width: 100%;
        margin-top: 48px;
        &.center {
          text-align: center;
        }
        li {
          @include fz(16);
          line-height: 2.5em;
          color: $white;
        }
      }
    }
  }
}
