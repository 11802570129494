#contents {
  &.english {

    .sec--cmn__ttl::after {
      max-width: 286px;
      width: 80%;
      height: 1px;
      margin: 50px auto;
      @include sp {
        margin: 30px auto;

      }
    }
// ==============================================================================================================
//▼ environmental
//---------------------------------------------------------------------------------------------------------------
    .profile {
      background: $black;
      @include fz(16);
      line-height: 1.8em;
      color: $black;
      @include sp {
        @include fz(14);
        line-height: 1.5em;
      }
      table {
        display: block;
        width: 100%;
        tbody {
          width: 100%;
          display: block;
        }
        tr {
          width: 100%;
          display: flex;
          th {
            width: 30%;
            color: $white;
            padding: 4px 4%;
            display: inline-block;
          }
          td {
            width: 70%;
            color: $white;
            padding: 4px 4%;
            display: inline-block;
          }
        }
      }
    }
    // ==============================================================================================================
    //▼ management
    //---------------------------------------------------------------------------------------------------------------
        .management, .management02 {
          background: $black02;
          .inner {
            padding-bottom: 80px;
            @include sp {
              padding-bottom: 40px;
            }
          }
          .sec--cmn__ttl {
            position: relative;
            top: -16px;
            margin-top: 16px;
          }
          .text--area {
            @include fz(16);
            line-height: 1.5em;
            color: $white;
            p + p {
              margin-top: 24px;
            }
          }
        }
        .management02 {
          background: $black;
        }
// ==============================================================================================================
//▼access
//---------------------------------------------------------------------------------------------------------------
    .access {
      background: $black02;
      &__cmn {
        width: 100%;
        background: $black02;
        & + .access__cmn {
          padding-top: 80px;
        }
        &__inner {
          width: 100%;
          .text--area {
            width: 100%;
            color: $white;
            a {
              color: $white;
              text-decoration: underline;
            }
            .name {
              @include fz(18);
              line-height: 1.3em;
              font-weight: bold;
              @include sp {
                padding-top: 30px;
                &.border {
                  border-top: 2px solid $white;
                }
              }
            }
            .address {
              @include fz(16);
              line-height: 1.5em;
              margin-top: 30px;
              @include tab {
                margin-top: 20px;
              }
            }
          }
        }
        .map {
          width: 100%;
          height: calc(462 / 1500 * 100vw);
          background: $black;
          margin-top: 60px;
          @include sp {
            height: calc(300 / 375 * 100vw);
            margin-top: 20px;
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
// ==============================================================================================================
//▼
//---------------------------------------------------------------------------------------------------------------
// ==============================================================================================================
//▼
//---------------------------------------------------------------------------------------------------------------
  }
}
