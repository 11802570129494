body {
  font-family: $font_noto;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: $black;
}

.sec--cmn {
  width: 100%;
  padding: 80px 0;
  position: relative;
  box-sizing: border-box;
  // @include tab {
  //   padding: 54px;
  // }
  &::before,
  &::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 53px;
    background: $black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // @include tab {
    //   height: 42px;
    // }
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  &:last-child{
    &::after {
      display: none;
    }
  }
  .inner {
    width: 90%;
    // max-width: calc(1024 / 1500 * 100vw);
    max-width: 1024px;
    margin: 0 auto;
    @include tab {
      max-width: none;
    }
    &.type--01 {
      max-width: calc(856 / 1500 * 100vw);
      @include tab {
        max-width: none;
      }
    }
    &.type--02 {
      // max-width: calc(775 / 1500 * 100vw);
      max-width: 890px;
      @include tab {
        max-width: none;
      }
    }
    &.type--03 {
      // max-width: calc(714 / 1500 * 100vw);
      max-width: 820px;
      @include tab {
        max-width: none;
      }
    }
  }
  &__ttl {
    text-align: center;
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: 80px;
      background: $black;
      margin: 0 auto;
      margin-top: 36px;
      // @include tab {
      //   height: 40px;
      //   margin-top: 24px;
      // }
    }
    .en {
      @include fz(14);
       background: $white;
       border: 1px solid $gray;
       padding: 8px 26px;
       display: inline-block;
    }
    .jp {
      @include fz(40);
      font-family: $font_yumin;
      display: block;
      margin-top: 40px;
    }
  }

  &.block--type{
    padding: 0;
    .block--type__inner {
      display: block;
      padding: 80px 0;
    }
  }
  &.white {
    &::before,
    &::after {
      background: $white;
    }
    .sec--cmn__ttl {
      .jp {
        color: $white;
      }
      &::after {
        background: $white;
      }
    }
  }
  &.none {
    &::before,
    &::after {
      display: none;
    }
  }
}

.detail--btn {
  font-weight: bold;
  background: $black;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.2);
  display: inline-block;
  width: 266px;
  text-align: center;
  a {
    @include fz(18);
    color: $white;
    display: block;
    padding: 28px 0;
    text-decoration: none;
    position: relative;
    @include sp {
      padding: 18px 0;
    }
    &::after {
      content: "";
      display: block;
      width: 35px;
      height: 20px;
      background: url(../../common/img/common/direction_hover.svg) center center /cover no-repeat;
      position: absolute;
      top: 50%;
      right: 8%;
      transform: translateY(-50%);
    }
  }
  &:hover {
    background: $white;
    a {
      color: $black;
      &::after {
        background-image: url(../../common/img/common/direction.svg);
      }
    }
  }
  &.white {
    background: $white;
    a {
      color: $black;
      &::after {
        background-image: url(../../common/img/common/direction.svg);
      }
    }
    &:hover {
      background: $black;
      a {
        color: $white;
        &::after {
          background: url(../../common/img/common/direction_hover.svg) center center /cover no-repeat;
        }
      }
    }
  }
}
