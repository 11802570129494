#contents {
  &.service {
    @include sp {
      .sec--cmn {
        padding: 20px 0;
      }
    }
    .point--01 .chatch {
      background: #B43660;
    }
    .point--02 .chatch {
      background: #2A896C;
    }
    .point--03 .chatch {
      background: #2A546A;
    }
    .point--04 .chatch {
      background: #5E842A;
    }
    .point--05 .chatch {
      background: #334A7E;
    }
    .double {
      .inner {
        display: flex;
        justify-content: space-between;
        @include sp {
          display: block;
        }
      }
      .double--point {
        width: 44%;
        @include sp {
          width: 100%;
        }
        .chatch {
          font-weight: bold;
          text-align: center;
          color: $white;
          padding: 30px 0;
          box-sizing: border-box;
          .fig {
            width: 100px;
            margin: 0 auto;
            @include sp {
              width: 75px;
            }
          }
          .number {
            @include fz(38);
            line-height: 1.5em;
            display: inline-block;
            margin-top: 20px;
            @include sp {
              @include fz(30);
              line-height: 1.5em;
              margin-top: 14px;
            }
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 3px;
              background: $gray;
            }
          }
          h2 {
            @include fz(24);
            line-height: 1.5em;
            margin-top: 14px;
            @include sp {
              @include fz(20);
              line-height: 1.5em;
            }
            &.type-01 {
              padding: 20px 0;
            }
          }
        }
        .text {
          @include fz(16);
          line-height: 1.8em;
          margin-top: 20px;
          padding-bottom: 30px;
          @include sp {
            @include fz(14);
            line-height: 1.8em;
          }
        }
      }
    }
    .single {
      .chatch {
        font-weight: bold;
        text-align: center;
        color: $white;
        background: $black02;
        padding: 30px 0;
        box-sizing: border-box;
        .fig {
          width: 100px;
          margin: 0 auto;
          @include sp {
            width: 75px;
          }
        }
        .number {
          @include fz(38);
          line-height: 1.5em;
          display: inline-block;
          margin-top: 20px;
          @include sp {
            @include fz(30);
            line-height: 1.5em;
            margin-top: 14px;
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background: $gray;
          }
        }
        h2 {
          @include fz(24);
          line-height: 1.5em;
          margin-top: 14px;
          @include sp {
            @include fz(20);
            line-height: 1.5em;
          }
        }
      }
      .text {
        @include fz(16);
        line-height: 1.8em;
        margin-top: 20px;
        padding-bottom: 30px;
        @include sp {
          @include fz(14);
          line-height: 1.8em;
        }
      }
    }
  }
}
//     .sec--cmn {
//       width: 100%;
//       height: 100vh;
//       max-height: 900px;
//       background: $white;
//       padding: 0;
//       position: relative;
//       // &.point--first {
//       //   background: url(../../common/img/service/bg_01.jpg) center center /cover no-repeat;
//       //   @include tab {
//       //     background: none;
//       //   }
//       // }
//       &.point--second {
//         background: url(../../common/img/service/bg_02.jpg) center center /cover no-repeat;
//       }
//       &.point--third {
//         background: url(../../common/img/service/bg_03.jpg) center center /cover no-repeat;
//         @include tab {
//           background: none;
//         }
//       }
//       @include tab {
//         height: auto;
//         max-height: none;
//       }
//     }
//     .inner {
//       position: relative;
//       height: 100%;
//       @include tab {
//         width: 85%;
//       }
//     }
//     .double,
//     .single {
//       @include tab {
//         .point--01 {
//           background: url(../../common/img/service/bg_sp_01.jpg) center center /cover no-repeat;
//         }
//         .point--02 {
//           background: url(../../common/img/service/bg_sp_02.jpg) center center /cover no-repeat;
//         }
//         .point--04 {
//           background: url(../../common/img/service/bg_sp_04.jpg) center center /cover no-repeat;
//         }
//         .point--05 {
//           background: url(../../common/img/service/bg_sp_05.jpg) center center /cover no-repeat;
//         }
//       }
//       .point--cmn {
//         background: $white;
//         &__inner {
//           margin: 0 auto;
//         }
//       }
//       .chatch {
//         font-weight: bold;
//         text-align: center;
//         color: $white;
//         background: $black02;
//         padding: 30px 0;
//         box-sizing: border-box;
//         .fig {
//           width: 100px;
//           margin: 0 auto;
//           @include sp {
//             width: 75px;
//           }
//         }
//         .number {
//           @include fz(38);
//           line-height: 1.5em;
//           display: inline-block;
//           margin-top: 20px;
//           @include sp {
//             @include fz(30);
//             line-height: 1.5em;
//             margin-top: 14px;
//           }
//           &::after {
//             content: "";
//             display: block;
//             width: 100%;
//             height: 3px;
//             background: $gray;
//           }
//         }
//         h2 {
//           @include fz(24);
//           line-height: 1.5em;
//           margin-top: 14px;
//           @include sp {
//             @include fz(20);
//             line-height: 1.5em;
//           }
//         }
//       }
//       .text {
//         @include fz(16);
//         line-height: 1.8em;
//         margin-top: 20px;
//         padding-bottom: 30px;
//         @include sp {
//           @include fz(14);
//           line-height: 1.8em;
//         }
//       }
//     }
//     .double {
//       .flex--box {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         position: absolute;
//         bottom: 0;
//         left: 50%;
//         transform: translateX(-50%);
//         @include tab {
//           flex-direction: column;
//           position: static;
//           transform: none;
//         }
//       }
//       .double--point {
//         width: 43%;
//         @include tab {
//           width: 100%;
//         }
//       }
//       .point--01,
//       .point--04 {
//         .point--cmn {
//           margin-right: 5%;
//           margin-left: auto;
//           @include tab {
//             margin-right: auto;
//           }
//         }
//       }
//       .point--02,
//       .point--05 {
//         .point--cmn {
//           margin-left: 5%;
//           margin-right: auto;
//           @include tab {
//             margin-left: auto;
//           }
//         }
//       }
//         .point--cmn {
//           width: calc(484 / 750 * 100%);
//           height: 100%;
//           padding: 0 10%;
//           // &:nth-child(2){
//           //   margin-left: calc(56 / 1500 * 100vw);
//           // }
//           @include tab {
//             width: 85%;
//             box-sizing: border-box;
//             margin: 300px auto 0;
//             padding: 0;
//             &:nth-child(2){
//               margin-left: 0;
//             }
//           }
//           @include sp {
//             margin-top: 200px;
//           }
//           &__inner {
//             @include tab {
//               width: 85%;
//             }
//           }
//           .chatch {
//             position: relative;
//             height: 362px;
//             @include sp {
//               height: auto;
//             }
//             &__inner {
//               position: absolute;
//               top: 50%;
//               left: 50%;
//               transform: translate(-50%, -50%);
//               @include sp {
//                 position: static;
//                 transform: none;
//               }
//             }
//           }
//         }
//     }
//     .single {
//       @include tab {
//         padding-top: 300px;
//       }
//       @include sp {
//         padding-top: 200px;
//       }
//       .point--cmn {
//         width: 100%;
//         position: absolute;
//         bottom: 0;
//         @include tab {
//           position: static;
//         }
//         &__inner {
//           width: 90%;
//         }
//       }
//     }
//   }
// }
